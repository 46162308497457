import { Component, ReactNode } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
import {
  appendSurvey,
  flattenNodes,
  getItem,
  getServiceUrl,
  redirectToUrl,
  ServiceData,
  PopupPropsType,
  parseJwt,
  RoleTypeName,
  setItem,
} from "../utilities";

import { TryItPopUp } from "./TryItShow";
import "../assets/styles/service.css";
import Card from "./Card";
import { License } from "../interfaces/license";
import Graph from "./Graph/Graph";
import { connect } from "react-redux";
import {
  getNodes,
  setLoading,
  setPopup,
  unsetLoading,
  unsetPopup,
  onSuccess,
  onError,
  unsetServices,
  getNotification,
  setSignOutTimeoutId,
} from "../reducers";
import { reload, rememberMe, validateMotionUser } from "../services";
import config from "../config";
import { State } from "../store";

interface ServicesProperties {
  nodes: any;
  setPopup: Function;
  unsetPopup: Function;
  getNodes: Function;
  setLoading: Function;
  onSuccess: Function;
  onError: Function;
  unsetLoading: Function;
  unsetServices: Function;
  getNotification: Function;
  signOutTimeoutId: NodeJS.Timeout | string | number | undefined;
  setSignOutTimeoutId: Function;
}

interface ServicesState {
  sideBarPopService: string;
  sideBarOptions: any[];
  flattenedNodes: any[];
  showSideBarPopup: boolean;
  tryItLink: string;
  showTryItPopup: boolean;
  serviceData: typeof ServiceData;
  score: number;

}

class Services extends Component<ServicesProperties, ServicesState> {
  name = getItem()?.user?.name;

  constructor(props: any) {
    super(props);
    this.state = {
      showTryItPopup: false,
      showSideBarPopup: false,
      sideBarOptions: [],
      flattenedNodes: [],
      tryItLink: "",
      sideBarPopService: "",
      serviceData: ServiceData,
      score: 0,
    };
  }

  async componentDidMount(): Promise<void> {
    try {
      this.props.setLoading();
      await this.getRememberMeToken();
      const response = await this.props.getNodes();
      await this.props.getNotification();
      const flattenedNodes = flattenNodes(response.payload, []);
      const services = this.getActiveServices(response);
      this.setState({
        sideBarPopService: "",
        flattenedNodes,
        sideBarOptions: flattenedNodes,
        showSideBarPopup: false,
        showTryItPopup: false,
        serviceData: services as any,
      });
      appendSurvey(response.payload[0].name);
      this.props.unsetLoading();
      this.startSignOutTimeout();
    } catch (err) {
      this.props.unsetLoading();
    }
  }

  getRememberMeToken = async () => {
    try {
      const { remember_me_token: rememberMeToken, domain } = getItem();
      if (rememberMeToken) {
        const response = await rememberMe(rememberMeToken);
        setItem(response.data.data);
        const motionToken = response.data?.data?.tokens?.motion;
        if (motionToken) {
          const tokenParams = parseJwt(motionToken);
          const timePassed = Math.floor(Date.now() / 1000) - tokenParams?.iat;
          if (timePassed > config.tokenExpiry) {
            console.error("Motion token expired.");
            const reloadReponse = await reload(
              response.data.data?.remember_me_token,
              domain
            );
            setItem(reloadReponse.data.data);
          }
        }
      } else {
        const token = getItem().tokens["portal"];
        if (token) {
          const response = parseJwt(token);
          const msLeft = response.exp * 1000 - Date.now();
          if (msLeft < 0) {
            this.props.unsetServices();
            this.props.onError({
              message: "Your token is expired. Please login again.",
            });
          }
        }
      }
    } catch (error) {
      this.props.unsetServices();
      this.props.onError({
        message: "Your token is expired. Please login again.",
      });
    }
    return null;
  };

  render(): ReactNode {
    const contextNode = this.state.flattenedNodes?.filter(node => node.hostname.includes(window.location.hostname))
    const isExternal = contextNode?.[0]?.has_external_domain ?? this.props.nodes?.has_external_domain;
    return (
      <div>
        <Header />
        <div className="greeting">{`👋 Welcome back ${this.name}!`}</div>
        <div className="service-left">
          {this.state.serviceData.map((serviceData, index) => (
            <Card
              key={`services-${index}`}
              data={serviceData as any}
              onTryItClick={(serviceTitle: string) =>
                this.handleServiceTryItClick(serviceTitle)
              }
              onServiceClick={(license: string) =>
                this.handleServiceClick(license)
              }
            />
          ))}
        </div>
        <div className="service-right">
          <Graph />
        </div>
        {this.state.showTryItPopup && (
          <div
            className="backdrop"
            onMouseDown={() => this.setState({ showTryItPopup: false })}
          >
            <TryItPopUp tryItLink={this.state.tryItLink} />
          </div>
        )}

        <SideBar
          serviceTitle={this.state.sideBarPopService}
          isExternal={isExternal}
          sidebarOptions={this.state.sideBarOptions}
          isOpen={this.state.showSideBarPopup}
          close={() =>
            this.setState({
              showSideBarPopup: !this.state.showSideBarPopup,
            })
          }
        />
      </div>
    );
  }

  handleServiceClick = async (license: string) => {
    const contextNode = this.state.flattenedNodes?.filter(node => node.hostname.includes(window.location.hostname))
    const isExternal = contextNode?.[0]?.has_external_domain ?? this.props.nodes?.has_external_domain;
    switch (license) {
      case License.PORTAL:
        redirectToUrl(getServiceUrl(license, null, null, null, isExternal));
        break;
      case License.AGENT_USER: {
        this.props.setLoading();
        const {
          tokens: { motion },
          user: { motion_node_id, motion_user_id },
        } = getItem();
        // Removing beacuse token already renewed  or refreshed.
        // const token = parseJwt(motion);
        // const msLeft = token.exp * 1000 - Date.now();
        // if (msLeft) {
        //   this.props.setPopup({ type: PopupPropsType.SESSION_TIMEOUT });
        //   return;
        // }
        const nodeId = motion_node_id;
        const node = this.findNode(nodeId);
        const host = node.motion_host || node.hostname;
        if (host) {
          try {
            const reponse = await validateMotionUser({
              host: host,
              user_id: motion_user_id,
              token: motion,
            });
            if (reponse.status === 200) {
              this.handleMotionPostClick();
              window.open(
                getServiceUrl(license, host, null, null, isExternal),
                "_blank"
              );
            } else {
              this.props.setPopup({
                type: PopupPropsType.DEFAULT,
                message:
                  "Something went wrong when trying to sign in to motion!",
              });
            }
          } catch (error) {
            this.props.setPopup({
              type: PopupPropsType.DEFAULT,
              message: error.message,
            });
          }
        }
        this.props.unsetLoading();
        break;
      }
      case License.SKYVIEW:
      case License.INSIGHTS: {
        const data = this.state.flattenedNodes.filter((data: any) =>
          license === License.INSIGHTS ? data.insights : data.skyview
        );
        if (data.length === 1) {
          window.open(
            getServiceUrl(
              license,
              data[0].hostname,
              data[0].name,
              data[0].eviexa,
              isExternal
            ),
            "_blank"
          );
        } else {
          this.setState({
            sideBarOptions: data,
            sideBarPopService: license,
            showSideBarPopup: true,
            showTryItPopup: false,
          });
        }
        break;
      }
      case License.SURVEY: {
        const data = this.state.flattenedNodes.filter(
          (data: any) => data.organization_survey_license && data?.eviexa
        );
        if (data.length) {
          window.open(
            getServiceUrl(
              license,
              data[0].hostname,
              data[0].name,
              data[0].eviexa,
              isExternal
            ),
            "_blank"
          );
        }
        break;
      }
      default:
        break;
    }
  };
  getActiveServices = (response: any) => {
    return ServiceData.map((service) => {
      if (service.id === License.INSIGHTS)
        return {
          ...service,
          isDisabled: !response.payload[0].insights_license,
        };
      else if (service.id === License.SKYVIEW)
        return {
          ...service,
          isDisabled: !response.payload[0].skyview_license,
        };
      else if (service.id === License.PORTAL) {
        const isDisabledPortal = !(
          getItem()?.user?.role_id <= RoleTypeName.manager
        );
        return {
          ...service,
          isDisabled: isDisabledPortal,
        };
      } else if (service.id === License.SURVEY)
        return {
          ...service,
          isDisabled:
            response?.payload[0]?.nodes?.filter(
              (n: any) => !!n.eviexa && response?.payload[0]?.survey_license
            ).length === 0,
        };
      else if (service.id === License.AGENT_USER) {
        const isDisabledAgent = !getItem().tokens?.motion;
        return { ...service, isDisabled: isDisabledAgent };
      } else return service;
    });
  };

  handleServiceTryItClick = (tryItLink: string) => {
    this.setState({
      tryItLink: tryItLink,
      showTryItPopup: true,
      showSideBarPopup: false,
    });
  };

  handleMotionPostClick() {
    const services = this.state.serviceData.map((service) => {
      if (service.id === License.AGENT_USER) {
        service.pointerEvent = true;
      }
      return service;
    });

    this.setState({ serviceData: [...services] });
    setTimeout(() => {
      const services = this.state.serviceData.map((service) => {
        if (service.id === License.AGENT_USER) {
          service.pointerEvent = true;
        }
        return service;
      });
      this.setState({ serviceData: [...services] });
    }, 10000);
  }
  startSignOutTimeout() {
    const signOutOrRefresh = () => {
      const token = parseJwt(getItem().tokens["portal"]);
      const msLeft = token.exp * 1000 - Date.now();
      if (msLeft < 0) {
        this.props.setPopup({ type: PopupPropsType.SESSION_TIMEOUT });
      }
    };
    let signOutTimeoutId = this.props.signOutTimeoutId;
    if (!signOutTimeoutId) {
      signOutTimeoutId = setInterval(() => signOutOrRefresh(), 60000);
      this.props.setSignOutTimeoutId(signOutTimeoutId);
    }
  }

  findNode(nodeId) {
    return this.state.flattenedNodes.find((node) => node.id === nodeId);
  }
}

const mapStateToProps = (state: State) => ({
  signOutTimeoutId: state.pageType.signOutTimeoutId,
  nodes: state.nodes
});

export default connect(mapStateToProps, {
  setPopup,
  unsetPopup,
  getNodes,
  setLoading,
  unsetLoading,
  onSuccess,
  onError,
  unsetServices,
  getNotification,
  setSignOutTimeoutId,
})(Services);
