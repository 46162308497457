import React, { Component } from "react";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HCMore from "highcharts/highcharts-more"; // Required for Gauge series
import HighchartsGauge from "highcharts/modules/solid-gauge"; // Required for Gauge series

HCMore(Highcharts);
HighchartsGauge(Highcharts);

type GaugeChartProps = {
  value: number;
  title?: string;
  metricTitle?: string;
  plotDirection?: GaugeDirection
};

export enum GaugeDirection {
  DEFAULT = "default",
  REVERSE = "reverse"
}

export class GaugeChart extends Component<GaugeChartProps> {

  render() {
    const options = this.getOptions();
    return <HighchartsReact highcharts={Highcharts} options={options} />;
  }

  private getOptions() {
    return {
      chart: {
        type: "gauge",
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        backgroundColor: "transparent",
        width: 270,
        height: 200,
        margin: 40,
        marginTop: this.props.title ? 0 : 0,
        marginBottom: 0,
        spacingTop: 0,
        spacingBottom: 20
      },
      title: this.getPerformanceResultTitle(),
      pane: {
        startAngle: -90,
        endAngle: 90,
        background: null,
        size: "100%",
      },
      tooltip: {
        enabled: true,
      },
      yAxis: {
        min: 0,
        max: 100,
        tickPixelInterval: 72,
        tickPosition: "inside",
        tickColor: Highcharts.defaultOptions.chart.backgroundColor || "#FFFFFF",
        tickLength: 0,
        tickWidth: 0,
        minorTickInterval: null,
        labels: {
          enabled: false,
          distance: 10,
          style: {
            fontSize: "14px",
            color: "black",
          },
        },
        plotBands: this.getPlotBands(),
      },
      series: [
        {
          name: this.props.metricTitle,
          data: [this.props.value],
          tooltip: {
            valueSuffix: " ",
          },
          dataLabels: {
            format: `{y}% ${this.props.metricTitle}`,
            borderWidth: 0,
            color: "#282828",
            padding: 30,
            style: {
              fontSize: "16px",
              fontWeight: "100",
              textOutline: undefined
            },
            textPath: {
              enabled: false
            }
          },
          dial: {
            radius: "90%",
            backgroundColor: "#1a2454",
            baseWidth: 10,
            baseLength: "0%",
            rearLength: "0%",
          },
          pivot: {
            backgroundColor: "#1a2454",
            radius: 6,
          },
        },
      ],
      credits: {
        enabled: false
      },
      plotOptions: {
        gauge: {
          animation: {
            duration: 3000
          },
          pointStart: 2
        },
        series: {
          states: {
            hover: {
              dial: {
                radius: "100%",
              },
            },
          },
        },
      },
    };
  }

  private getPlotBands() {
    let plotBands = [
      {
        from: 0,
        to: 26,
        className: "test12133",
        label: {
          rotation: 0,
          style: {
            color: "red",
            fontSize: "14px"
          },
          useHTML: true,
          textAlign: "left",
          text: "<div style='padding-top: 63px;margin-left: 25px; color: grey'><b>LOW</b></div>"
        },
        color: {
          radialGradient: { cx: 0.3, cy: 0.8, r: 0.7 },
          stops: [
            [0, 'red'],
            [1, 'orange']
          ]
        }, // red
        thickness: 30,
      },
      {
        from: 25,
        to: 50,
        color: {
          radialGradient: { cx: 0.1, cy: 0.8, r: 1.4 },
          stops: [
            [0, 'orange'],
            [2, 'yellow']
          ]
        },
        thickness: 30,
      },
      {
        from: 50,
        to: 75,
        color: {
          radialGradient: { cx: 0.1, cy: 0.3, r: 1.5 },
          stops: [
            [0, 'yellow'],
            [1, '#55BF3B']
          ]
        },
        thickness: 30,
      },
      {
        from: 75,
        to: 100,
        label: {
          useHTML: true,
          textAlign: "center",
          text: "<div style='padding-top: 61px;margin-left: 20px; color: grey'><b>EXCELLENT</b></div>",
          style: {
            color: "green",
            fontSize: "14px",
            textAlign: "left",
            width: "150px",
            marginLeft: "0px",
            display: "block"
          },
        },
        color: {
          radialGradient: { cx: 0.4, cy: 0.2, r: 0.7 },
          stops: [
            [0, '#bde52f'],
            [1, '#55BF3B']
          ]
        }, // green
        thickness: 30,
      },
    ]


    return plotBands;
  }

  getPerformanceResultTitle() {
    let title = "You could do better!";
    let color = "red"

    if(this.props.value > 50 && this.props.value <= 75) {
      title = "Good job!";
      color = "orange"
    } else if(this.props.value > 75 && this.props.value <= 100) {
      title = "Excellent job!";
      color = "#55BF3B"
    }

    return {
      text: " " || title,
      style: {
        marginTop: "30px",
        fontFamily: "inter",
        fontSize: "14px",
        fontWeight: 800,
        color,
        animationName: "gaugeTitle",
        animationDuration: "3s"
      },
    }
  }
};
