import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NodeListStateType } from "../interfaces";
import { getNodesService } from "../services";

export const getNodes = createAsyncThunk("nodeList", async () => {
  return getNodesService()
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw new Error(error);
    });
});

const nodeListSlice = createSlice({
  name: "nodeList",
  initialState: null,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNodes.fulfilled, (state: NodeListStateType, { payload }) => {
        return { ...payload[0] };
      })
      .addCase(getNodes.rejected, (state: NodeListStateType) => {
        state = null;
      });
  },
});

// destructure actions and reducer from the slice (or you can access as nodeListSlice.actions)
const { reducer } = nodeListSlice;

// export individual action creator functions
// export const {getNodes} = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
