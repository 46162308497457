import { createSlice } from "@reduxjs/toolkit";
import { snackbarPropTypes } from "../interfaces";

type snackbarStateType = {
  message: string;
  variant: snackbarPropTypes;
};
const initialState: snackbarStateType = {
  variant: snackbarPropTypes.NO,
  message: "",
};

const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    onSuccess: (state, action) => ({
      variant: snackbarPropTypes.SUCCESS,
      message: action.payload.message,
    }),
    onError: (state, action) => ({
      variant: snackbarPropTypes.ERROR,
      message: action.payload.message,
    }),
    closeSnackbar: (state) => ({
      variant: snackbarPropTypes.NO,
      message: "",
    }),
  },
});

// destructure actions and reducer from the slice (or you can access as snackbarSlice.actions)
const { actions, reducer } = snackbarSlice;

// export individual action creator functions
export const { onSuccess, onError, closeSnackbar } = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
