import { TwoFactorAuth, LoginForm, ForgotPasswordForm } from "./Forms";
import React, { Component } from "react";
import { connect } from "react-redux";
import { getItem, handleRedirections, isUserLoggedIn, PagePropsType, setItem } from "../utilities";
import { LoginLayout } from "../layouts";
import { setServices, setLoading, unsetLoading, PageRoute } from "../reducers";

interface HomeState {
  pageType: PagePropsType;
  rememberMe: number;
  rememberMeToken: string;
}

class Home extends Component<
  { setServices: Function; setLoading: Function; unsetLoading: Function },
  HomeState
> {
  constructor(props) {
    super(props);
    const rememberMeToken = getItem().remember_me_token ?? "";
    this.state = {
      pageType: PagePropsType.LOGIN,
      rememberMe: 0,
      rememberMeToken,
    };
  }
  async componentDidMount() {
    this.props.setLoading();
    const value = isUserLoggedIn();
    this.setState({ pageType: value });
    this.props.unsetLoading();
  }

  render(): React.ReactNode {
    switch (this.state.pageType) {
      case PagePropsType.LOGIN:
        return (
          <LoginLayout handlePageChange={this.handlePageChange}>
            <LoginForm
              onSuccessfullySubmit={this.onSuccessfullySubmit}
              handlePageChange={this.handlePageChange}
            />
          </LoginLayout>
        );
      case PagePropsType.TWO_FACTOR_AUTH:
        return (
          <LoginLayout
            page={PagePropsType.TWO_FACTOR_AUTH}
            handlePageChange={this.handlePageChange}
          >
            <TwoFactorAuth
              onSuccessfulOf2FASubmit={(response) =>
                this.onSuccessfulOf2FASubmit(response)
              }
            />
          </LoginLayout>
        );
      case PagePropsType.FORGET_PASSWORD:
        return (
          <LoginLayout
            page={PagePropsType.FORGET_PASSWORD}
            handlePageChange={this.handlePageChange}
          >
            <ForgotPasswordForm
              handlePageChange={(page: PagePropsType) =>
                this.handlePageChange(page)
              }
            />
          </LoginLayout>
        );
      default:
        return (
          <LoginLayout handlePageChange={this.handlePageChange}>
            <LoginForm
              onSuccessfullySubmit={(response: any) =>
                this.onSuccessfullySubmit(response)
              }
              handlePageChange={(page: PagePropsType) =>
                this.handlePageChange(page)
              }
            />
          </LoginLayout>
        );
    }
  }

  handlePageChange = (PageType: PagePropsType) => {
    this.setState({
      pageType: PageType,
    });
  };

  onSuccessfullySubmit = (response: any) => {
    setItem(response.data);
    if (response.data.two_factor_auth) {
      this.setState({
        pageType: PagePropsType.TWO_FACTOR_AUTH,
      });
    } else {
      this.handleSuccessfulLogin()
    }
  };

  onSuccessfulOf2FASubmit = (response: any) => {
    if (response.data.tokens) {
      this.handleSuccessfulLogin()
    }
    setItem(response.data);
  };

  handleSuccessfulLogin() {
    this.props.setServices();
    handleRedirections(PageRoute.SERVICES, (url) => window.location.href = url)
  }
}

export default connect(undefined, { setServices, setLoading, unsetLoading })(
  Home
);
