import { cookiePropTypes } from "../interfaces";
import { PagePropsType } from "./constants";

const setItem = (data: any) => {
  if (data.two_factor_auth) {
    data.domain && localStorage.setItem("domain", data.domain);
    data.two_factor_session_token &&
      localStorage.setItem(
        "two_factor_session_token",
        data.two_factor_session_token
      );
    data.otpauth_url && localStorage.setItem("otpauth_url", data.otpauth_url);
    data.remember_me && localStorage.setItem("remember_me", data.remember_me);
    data.mfa_notify_email && localStorage.setItem("mfa_notify_email", data.mfa_notify_email);
  } else {
    data.domain && localStorage.setItem("domain", data.domain);
    data.refresh_token &&
      localStorage.setItem("refresh_token", data.refresh_token);
    data.remember_me_token &&
      localStorage.setItem("remember_me_token", data.remember_me_token);
    data.tokens && localStorage.setItem("tokens", JSON.stringify(data.tokens));
    data.user && localStorage.setItem("user", JSON.stringify(data.user));
    data.remember_me && localStorage.setItem("remember_me", data.remember_me);
  }
};
const getItem = (): cookiePropTypes => ({
  tokens: JSON.parse(
    localStorage.getItem("tokens") ? localStorage.getItem("tokens") : "{}"
  ),
  user: JSON.parse(
    localStorage.getItem("user") ? localStorage.getItem("user") : "{}"
  ),
  domain: localStorage.getItem("domain"),
  two_factor_session_token: localStorage.getItem("two_factor_session_token"),
  remember_me: localStorage.getItem("remember_me"),
  remember_me_token: localStorage.getItem("remember_me_token"),
  otpauth_url: localStorage.getItem("otpauth_url"),
  mfa_notify_email: localStorage.getItem("mfa_notify_email")
});

const removeItem = () => {
  [
    "tokens",
    "remember_me",
    "remember_me_token",
    "user",
    "otpauth_url",
    "two_factor_session_token",
    "refresh_token",
    "mfa_notify_email",
  ].forEach((item) => localStorage.removeItem(item));
};

const isUserLoggedIn = (): PagePropsType => {
  const two_factor_session_token = localStorage.getItem(
    "two_factor_session_token"
  );
  const tokens = localStorage.getItem("tokens");
  if (!tokens && two_factor_session_token) {
    return PagePropsType.TWO_FACTOR_AUTH;
  } else {
    return PagePropsType.LOGIN;
  }
};

export { setItem, removeItem, isUserLoggedIn, getItem };
