const config = {
  portal: process.env.REACT_APP_PORTAL,
  portalUI: process.env.REACT_APP_PORTAL_UI,
  notification: process.env.REACT_APP_NOTIFICATION_DOMAIN,
  socketPath: process.env.REACT_APP_SOCKET_PATH,
  socketHost: process.env.REACT_APP_SOCKET_HOST,
  corePath: process.env.REACT_APP_CORE_PATH,
  tokenExpiry: 14 * 60 * 60,
  loginCookieDomain: process.env.REACT_APP_LOGIN_COOKIE_DOMAIN,
  loginCookieExpiry: parseInt(process.env.REACT_APP_LOGIN_COOKIE_EXPIRY),
  environment: process.env.REACT_APP_ENVIRONMENT
};
export default config;
