import React from "react";
import { connect } from "react-redux";
import {
  setLoading,
  unsetLoading,
  unsetPopup,
  unsetServices,
} from "../../../reducers";
import "../../../assets/styles/sessionTimeout.css";
import { getItem, setItem } from "../../../utilities";
import { reload } from "../../../services";
interface SessionTimeoutProps {
  unsetPopup: Function;
  setLoading: Function;
  unsetLoading: Function;
  unsetServices: Function;
}

class SessionTimeout extends React.Component<SessionTimeoutProps> {
  token = getItem().remember_me_token;
  action = this.token ? "reload" : "reLogin";

  render() {
    return (
      <div className="Session-timeout-box">
        <div className="Session-timeout-header">Session timeout.</div>
        <div
          className="Session-timeout-btn btn"
          onClick={this.handleReloadClick}
        >
          {this.action === "reload" ? "Reload" : "Relogin"}
        </div>
      </div>
    );
  }

  handleReloadClick = async () => {
    this.props.setLoading();
    switch (this.action) {
      case "reload":
        this.reload();
        break;
      case "reLogin": {
        this.props.setLoading();
        this.props.unsetPopup();
        this.props.unsetServices();
        this.props.unsetLoading();
      }
    }
    this.props.unsetLoading();
  };

  async reload() {
    const domain = localStorage.getItem("domain") ?? "";
    const remember_me_token = getItem().remember_me_token;
    if (remember_me_token) {
      try {
        const response = await reload(remember_me_token, domain);
        setItem(response.data.data);
        this.props.unsetPopup();
      } catch (e) {
        this.props.unsetServices();
      }
    }
  }
}

export default connect(undefined, {
  unsetPopup,
  setLoading,
  unsetLoading,
  unsetServices,
})(SessionTimeout);
