import React from "react";
import { connect } from "react-redux";
import { unsetPopup } from "../../../reducers";
import "../../../assets/styles/sessionTimeout.css";
interface DefaultProps {
  unsetPopup: Function;
  message: string;
}

class Default extends React.Component<DefaultProps> {
  render() {
    return (
      <div className="Session-timeout-box">
        <div className="Session-timeout-header">
          {this.props.message ?? "Something went wrong please retry!!"}
        </div>
        <div className="Session-timeout-btn btn" onClick={this.close}>
          {" "}
          Close
        </div>
      </div>
    );
  }

  close = () => {
    this.props.unsetPopup();
  };
}

export default connect(undefined, { unsetPopup })(Default);
