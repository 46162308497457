import React from "react";
import { loading } from "../assets/images/index";
import { State } from "../store";
import { connect } from "react-redux";
import "../assets/styles/loading.css";
interface LoadingProps {
  isLoading: number;
}

class Loading extends React.Component<LoadingProps> {
  render() {
    return this.props.isLoading ? (
      <div className="loading">
        <img className="lds-dual-ring" src={loading} alt="/loader"></img>
      </div>
    ) : (
      <></>
    );
  }
}
const mapStateToProps = (state: State) => ({
  isLoading: state.isLoading,
});

export default connect(mapStateToProps,  undefined)(Loading);
