import { License } from "../interfaces/license";
import Icon from "./Icons";
import { insights, motion, portal, skyview, survey } from "../assets/images";

const ServiceData = [
  {
    id: License.SKYVIEW,
    title: "Skyview",
    description: "Real time data analysed",
    imageUrl: skyview,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1xC--xEycTq6in-akf4bvtw2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/data-and-analysis/dashboard/",
  },
  {
    id: License.INSIGHTS,
    title: "Insights",
    description: "All the insights you need in our reporting tool",
    imageUrl: insights,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1ID5qNUuXSjO0dMH7DKqNjQ2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/data-and-analysis/analytics/",
  },
  {
    id: License.PORTAL,
    title: "Portal",
    description: "Manage your Organization",
    imageUrl: portal,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1kB5kNg4eT4mA5DUF9tDyrQ2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/",
  },
  {
    id: License.AGENT_USER,
    title: "Agent/User",
    description: " Manage calls & interactions as an agent",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1kB5kNg4eT4mA5DUF9tDyrQ2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/contact-center/voice/",
  },
  {
    id: License.SURVEY,
    title: "Survey",
    description: "Track customers in real time",
    imageUrl: survey,
    tryIt: "try it free",
    tryItLink: " https://share.hsforms.com/1jNiEVKrzToCYzJZrJ074lw2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/data-and-analysis/survey/",
  },
  {
    id: License.WFM,
    title: "WFM",
    description: "Simplify planning and scheduling",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1eDFfki4RRD-eqsxhQkCawA2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/contact-center/wfm-workforce-management/",
  },
  {
    id: License.CHATBOT,
    title: "Chatbot",
    description: "Become available 24/7 with a smarter chatbot service",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1tMnJ2PXJS9Sso3660SxsMQ2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/self-service/chatbot/",
  },
  {
    id: License.VOICEBOT,
    title: "Voicebot",
    description: "The bot that helps your customers",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1WgFkKjz2SVCU32zWegmG-g2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/self-service/voicebot/",
  },
  {
    id: License.SPEECH,
    title: "Speech Analytics",
    description: "Unlock conversational intelligence with transcription",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: "https://share.hsforms.com/1B0cjzAknTz2hpssUWAW8gg2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore:
      "https://connectel.io/data-and-analysis/speech-analytics/",
  },
  {
    id: License.KNOWLEDGE_BASE,
    title: "Knowledge Base",
    description: "Increase the level of self service with a dynamic FAQ",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: " https://share.hsforms.com/1ZRiiYwzkRMW9r-nDZXELmw2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/self-service/knowledgebase/",
  },
  {
    id: License.TEXT_MESSAGE,
    title: "Text Messaging",
    description: "Gateway to send text messages",
    imageUrl: motion,
    tryIt: "try it free",
    tryItLink: " https://share.hsforms.com/1E2M0QQ0aRo-6oc11IKbgXA2khs1",
    isDisabled: true,
    pointerEvent: true,
    learnMore: "https://connectel.io/contact-center/sms/",
  },
];

enum PagePropsType {
  LOGIN = "login",
  FORGET_PASSWORD = "forget_password",
  TWO_FACTOR_AUTH = "two_factor_auth",
}

enum PopupPropsType {
  SESSION_TIMEOUT = "session_timeout",
  STATISTIC_MESSAGE = "statistic_message",
  DEFAULT = "default",
  NONE = "",
}

const RoleType = {
  1: "superadmin",
  2: "admin",
  3: "manager",
  4: "agent",
  5: "anonymous",
  99: "anonymous",
};
const RoleTypeName = {
  superadmin: 1,
  admin: 2,
  manager: 3,
  agent: 4,
  anonymous: 5 | 99,
};

const labels = ["0", "1", "2", "3", "4", "5", "6"];

const graphData = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [
        "5.3",
        "2.3",
        "1.2",
        "2.1",
        "5.4",
        "1",
        "2",
        "0.5",
        "0.6",
        "3.4",
        "2.6",
        "3",
        "5",
        "4.4",
        "5.4",
        "1",
      ],
      borderColor: "rgb(255, 99, 132)",
      backgroundColor: "rgba(255, 99, 132, 0.5)",
    },
    {
      label: "Dataset 2",
      data: [
        "1.4",
        "5.3",
        "2.2",
        "4.1",
        "3",
        "6",
        "1",
        "3.5",
        "5.6",
        "2.4",
        "5.6",
        "1",
        "2",
        "3.4",
        "4.4",
        "5",
      ],
      borderColor: "rgb(53, 162, 235)",
      backgroundColor: "rgba(53, 162, 235, 0.5)",
    },
  ],
};

const userDropdownOptions = [
  {
    onClick: () =>
      window.open("https://helpdesk.connectel.io/support/home", "_blank"),
    title: "FAQs",
    children: (
      <>
        <Icon icon="live_help" />
        <div> FAQs</div>
      </>
    ),
  },
  {
    onClick: () =>
      window.open(
        "https://helpdesk.connectel.io/support/tickets/new",
        "_blank"
      ),
    title: "Raise a Ticket",
    children: (
      <>
        <Icon icon="ticket" />
        <div>Raise a Ticket</div>
      </>
    ),
  },
  {
    onClick: undefined,
    title: "Sign out",
    children: (
      <>
        <Icon icon="switch" />
        <div>Logout</div>
      </>
    ),
  },
];

const PerformanceBenchmarkInfoElement = <div>
  Performance Benchmark makes it easy to compare your data and results with the top performers. <br /><br />
  With this feature, you can compare your own data and results with the top performers. This can help you identify areas where you excel and where you may need to improve, as well as provide valuable insights into how you can boost your performance and achieve better results. <br /><br />
  Each metric is presented as a percentage value that ranges from 0% (low) to 100% (excellent performance). Your value is calculated by comparing your results with those of leading organizations, so you can be confident that you're getting an accurate representation of your performance.
</div>;

export {
  ServiceData,
  RoleType,
  RoleTypeName,
  PopupPropsType,
  PagePropsType,
  graphData,
  userDropdownOptions,
  PerformanceBenchmarkInfoElement
};
