import { getItem } from "../utilities";
import io from "socket.io-client";
import config from "../config";

const sockets: any = {};
export const getPortalSocket = (): any => {
  const socketKey = "portal-server";
  const token = getItem().tokens.portal;
  if (!sockets.hasOwnProperty(socketKey)) {
    sockets[socketKey] = io.connect(`${config.socketHost}`, {
      path: config.socketPath,
      query: { token: `Bearer ${token}` },
      reconnection: false,
      transports: ["websocket"],
    });
  }

  return sockets[socketKey];
};
