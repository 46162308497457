import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getNotificationService } from "../services";

export const getNotification = createAsyncThunk("getNotification", async () => {
  return getNotificationService()
    .then((response) => {
      return response.data.data;
    })
    .catch(() => {
      return [];
    });
});
const notificationsSlice = createSlice({
  name: "notification",
  initialState: [],
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNotification.fulfilled, (state, { payload }) => payload)
      .addCase(getNotification.rejected, () => []);
  },
});

// destructure actions and reducer from the slice (or you can access as notificationsSlice.actions)
const { reducer } = notificationsSlice;

// often the reducer is a default export, but that doesn't matter
export default reducer;
