import React, { Component } from "react";
import { License } from "../interfaces/license";
import "../assets/styles/card.css";
import Icon from "../utilities/Icons";

interface ServiceProps {
  data: {
    title: string;
    description: string;
    imageUrl: string;
    tryIt: string;
    isDisabled: boolean;
    learnMore: string;
    pointerEvent: boolean;
    tryItLink: string;
  };
  onTryItClick: Function;
  onServiceClick: Function;
}

interface ServiceState {}

export default class Service extends Component<ServiceProps, ServiceState> {
  render() {
    const serviceData = this.props.data;
    const license = serviceData.title.toLowerCase() as License;
    return (
      <div
        className={`card ${serviceData.isDisabled || !serviceData.pointerEvent ? "in-active-card" : ""}`}
        onClick={() => this.props.onServiceClick(license)}
      >
        {!serviceData.isDisabled && (
          <Icon icon="check_circle" className="card-icon-check" />
        )}
        <img className={"img"} src={serviceData.imageUrl} alt="" />
        <div className={"title"}>{serviceData.title}</div>
        <p className={"description"}>{serviceData.description}</p>
        {serviceData.isDisabled && (
          <div className="bottom">
            <div
              className="card-button"
              onClick={(event) =>
                this.handleTryItClick(event, serviceData.tryItLink)
              }
            >
              {serviceData.tryIt}
            </div>
            <span
              className="learn-more"
              onClick={(event) => {
                event.stopPropagation();
                window.open(serviceData.learnMore, "_blank");
              }}
            >
              Learn more
            </span>
          </div>
        )}
      </div>
    );
  }

  handleTryItClick = (e: any, tryItLink: string) => {
    e.stopPropagation();
    this.props.onTryItClick(tryItLink);
  };
}
