import { BenchmarkDuration } from "connectel-shared/constants/Benchmark";
import { BenchmarkMetricResponse } from "connectel-shared/interfaces/Benchmark";
import {
  ForgotPasswordAuthRequest,
  LoginRequest,
  TwoFactorAuthRequest,
} from "../interfaces";
import PortalInstance from "./url";
import { SAMLLoginRequest } from "../interfaces/login.interfaces";

const getNodesService = async () => {
  return await PortalInstance({
    method: "GET",
    url: "node/list",
  });
};

const getNodeConfig = async () => {
  return await PortalInstance({
    method: "GET",
    url: "node/config",
  });
};

const validateMotionUser = async (body) => {
  return await PortalInstance({
    method: "POST",
    url: "auth/motion/login",
    data: body,
  });
};

const getNotificationService = async () => {
  return await PortalInstance({
    method: "GET",
    url: "notifications",
  });
};

const login = async (data: LoginRequest) => {
  return await PortalInstance({
    method: "POST",
    url: "auth/login",
    data: data,
  });
};

const samlLogin = async (data: SAMLLoginRequest) => {
  return await PortalInstance({
    method: "POST",
    url: "auth/motion-authenticate",
    data: data,
  });
};

const postNotificationService = async (_body: any) => {
  return await PortalInstance({
    method: "PUT",
    url: "notifications",
    data: _body,
  });
};

const rememberMe = async (remember_me_token: string) => {
  return PortalInstance({
    method: "POST",
    url: "auth/login/",
    data: { remember_me_token },
  });
};

const reload = async (remember_me_token: string, domain: string) => {
  return PortalInstance({
    method: "POST",
    url: "/auth/login/reload",
    data: {
      remember_me_token,
      domain,
    },
  });
};
const signOutService = async () => {
  return await PortalInstance({
    method: "GET",
    url: "auth/signout",
  });
};

const TwoFactor = async (requestBody: TwoFactorAuthRequest) => {
  return await PortalInstance({
    method: "POST",
    url: "auth/two-factor",
    data: requestBody,
  });
};

const SendEmail = async (requestBody: {two_factor_session_token: string}) => {
  return await PortalInstance({
    method: "POST",
    url: "auth/two-factor/email",
    data: requestBody,
  });
};

const forgotPassword = async (requestBody: ForgotPasswordAuthRequest) => {
  return await PortalInstance({
    method: "POST",
    url: `auth/forgot`,
    data: requestBody,
  });
};

async function getGaugeData(id: string, metrics: string[], duration: BenchmarkDuration) {
  const gaugeData = await PortalInstance({
    method: "GET",
    url: `metric/compare?metrics=${metrics.join(",")}&site_id=${id}&duration=${duration}`,
  })
  return gaugeData?.data?.data as {
    benchmarkData: BenchmarkMetricResponse[]
  }
}

const sendPerformanceBenckmarkMail = async (requestBody) => {
  return await PortalInstance({
    method: "POST",
    url: `metric/share`,
    data: requestBody
  })
}

const getGaugeTrendsData = async (id: string, metricAlias: string, duration: BenchmarkDuration) => {
  return await PortalInstance({
    method: "GET",
    url: `metric/trends?metric=${metricAlias}&site_id=${id}&duration=${duration}`,
  })
}

export {
  getNotificationService,
  forgotPassword,
  getNodesService,
  login,
  samlLogin,
  postNotificationService,
  rememberMe,
  signOutService,
  TwoFactor,
  SendEmail,
  reload,
  validateMotionUser,
  getGaugeData,
  getGaugeTrendsData,
  sendPerformanceBenckmarkMail,
  getNodeConfig
};
