import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "../interfaces/popup.interfaces";

const popupSlice = createSlice({
  name: "popup",
  initialState: initialState,
  reducers: {
    setPopup: (state, action) => action.payload,
    unsetPopup: () => initialState,
  },
});

// destructure actions and reducer from the slice (or you can access as popupSlice.actions)
const { actions, reducer } = popupSlice;

// export individual action creator functions
export const { setPopup, unsetPopup } = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
