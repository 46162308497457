import React, { createRef } from "react";
import { Component } from "react";
import Icon from "../utilities/Icons";
import { GaugeChart, GaugeDirection } from "./Graph/GaugeChart";
import { BenchmarkDuration, BenchmarkMetrics } from "connectel-shared/constants/Benchmark";
import { getGaugeData, getGaugeTrendsData, sendPerformanceBenckmarkMail } from "../services";
import { State } from "../store";
import { connect } from "react-redux";
import { flattenNodes, getItem, PerformanceBenchmarkInfoElement } from "../utilities";
import "../assets/styles/performance.css";
import Tooltips from "../utilities/Tooltips";
import Chart from "./Graph/Chart";
import html2canvas from "html2canvas";
import { onError, onSuccess } from "../reducers";
import {motion} from "../assets/images/index";
import {
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	ListSubheader,
} from '@mui/material';
import { BenchmarkMetricResponse, BenchmarkQueryType } from "connectel-shared/interfaces/Benchmark";

const BenchmarkMetricsStorageKey = "benchmark_metrics";

interface GaugeStats {
  currentValue: number;
  benchmarked: number;
	percentage: number;
	average: string;
	trend: string;
	rowsAnalyzed: number;
}

interface GaugeChartOptions {
	alias: string;
	value: number;
	trend: string;
	average: string;
	title: string;
	shortTitle: string;
  unit: string;
	plotDirection: GaugeDirection;
  currentValue: number;
  benchmarked: number;
	description: string;
}

interface PerformanceProps {
	nodes: any;
  onSuccess: Function
  onError: Function
}

interface PerformanceState {
	flattenedNodes: any[];
	showGaugeMeter: boolean;
	showSharePopup: boolean;
	showFilterPopup: boolean;
	showScrollPointer: boolean;
	metricTrendsDataPoints: {time: string, value: number}[];
	metricTrendsShortTitle: string;
	duration: BenchmarkDuration;
	gaugeMeterValuesMap: Record<string, GaugeStats>; //metricAlias to stats
	lastFetchedTime: number;
	emails: string[];
	filteredMetrics: string[];
	selectedMetrics: string[];
}

class Performance extends Component<PerformanceProps, PerformanceState> {
  footer: React.RefObject<HTMLDivElement> = null;
  scalesContainer: React.RefObject<HTMLDivElement> = null;
  shareButton: React.RefObject<HTMLButtonElement> = null;
	constructor(props) {
		super(props);
    	this.footer = createRef();
		this.scalesContainer = createRef();
    	this.shareButton = createRef();
		this.state = {
			showGaugeMeter: false,
			showSharePopup: false,
			showFilterPopup: false,
			showScrollPointer: false,
			metricTrendsDataPoints: [],
			metricTrendsShortTitle: "",
			duration: BenchmarkDuration.TODAY_LIVE,
			flattenedNodes: [],
			gaugeMeterValuesMap: {},
			lastFetchedTime: Date.now(),
			emails: [],
			filteredMetrics: JSON.parse(localStorage.getItem(BenchmarkMetricsStorageKey) || '[]'),
			selectedMetrics:[],
		};
	}
	componentDidMount(): void {
		window.onresize = (e) => this.handleScrollPointerVisibility(this.scalesContainer.current)
	}

	componentDidUpdate(): void {
		this.handleScrollPointerVisibility(this.scalesContainer.current)
		if (this.state.flattenedNodes?.length === 0 && this.props?.nodes) {
      const flattenedNodes = [
        ...(this.props.nodes?.organizations?.length > 0 ? flattenNodes(this.props.nodes.organizations, []) : []),
        ...(this.props.nodes?.nodes?.length > 0 ? this.props.nodes?.nodes : [])
      ]
			this.setState({
				flattenedNodes,
			});
		}
	}

	render() {
		return (
			<React.Fragment>
				{this.getPerformanceMeterIcon()}
				{this.getGaugeScales()}
			</React.Fragment>
		);
	}

	getPerformanceMeterIcon(): React.ReactNode {
		if (this.isPerformanceIconVisible()) {
			return (
				<li
					className="list-icon"
					onClick={(e) => this.handlePerformanceIconClick(e)}
					style={{ cursor: "pointer" }}
				>
					<Icon
						tooltip={{
							id: "performance-meter",
							message: "Performance Benchmark",
							place: "top",
							variant: "light",
						}}
						icon="speed"
						className="speedo-meter"
					/>
					<span style={{ marginLeft: "10px", fontWeight: "none" }}>
						Performance
					</span>
				</li>
			);
		} else {
			return <></>;
		}
	}

	getGaugeScales(): React.ReactNode {
		const benchmarkMetrics = JSON.parse(localStorage.getItem(BenchmarkMetricsStorageKey) || '[]') as string[];
		return (
			this.state.showGaugeMeter && (
				<div className="fspopup">
					<div className="gauge-scales-popup" style={{backgroundColor:"#F5F5F5"}}>
						{this.getGaugePopupHeader()}
						{this.getSharePopup()}
						{this.getFilterPopup()}
						<div
							className="gauge-scales-flex-container"
              				ref={this.scalesContainer}
						>
							{ (benchmarkMetrics.length ? benchmarkMetrics : Object.keys(this.state.gaugeMeterValuesMap)).map(
								(metricAlias, i) => {
									const gaugeChartOptions =
										this.getGaugeScaleOptionsForMetric(
											metricAlias
										);

									return (
										<div
											className="gauge-scale-container"
											key={`performance-graph-${i}-${this.state.duration}-${this.state.lastFetchedTime}-${this.state.emails.length > -1}`}
											style={{height: `calc(560px - ${this.state.duration === BenchmarkDuration.TODAY_LIVE ? 65 : 0}px)`}}
											>
											{this.getNoDataLabel(this.state.gaugeMeterValuesMap[metricAlias])}
											{this.getMetricTitle(gaugeChartOptions)}
											<GaugeChart
												value={gaugeChartOptions?.value}
												title={""}
												metricTitle={gaugeChartOptions.shortTitle}
												plotDirection={
													gaugeChartOptions?.plotDirection
												}
											/>
											{this.getGaugeScaleOverallPerformance(
												gaugeChartOptions
											)}
											{this.getGaugeScaleStats(
												gaugeChartOptions
											)}
										</div>
									);
								}
							)}
              {this.getConnectelOwnershipFooter()}
						</div>
						{this.getTrendsGraph()}
						{this.getFooter()}
					</div>
				</div>
			)
		);
	}

  getGaugePopupHeader(): React.ReactNode {
    return <React.Fragment>
      <div className="gauge-scales-popup-button" style={{right: "110px"}}>
        <span className="refresh-icon" id="refresh-icon">
          <Icon
            icon="refresh"
            style={{ fontSize: "25px", verticalAlign: "middle" }}
            onClick={(e) => this.handleGaugesRefreshClick(e)}
            tooltip={{
              message: "Refresh",
              className: "info-tooltip wide tooltip-center",
              variant: "dark",
              place: "bottom",
              id: "refresh-icon",
            }}
          />
         </span>
         <span className="refresh-icon" id="share-report">
          <Icon
            icon="share2"
            style={{ fontSize: "18px", verticalAlign: "middle", marginLeft: "10px"}}
            onClick={(e) => this.handleShareButtonClick()}
            tooltip={{
              message: "Share",
              className: "info-tooltip wide tooltip-center",
              variant: "dark",
              place: "bottom",
              id: "share-report",
            }}
          />
         </span>
          <span className="filter-icon" id="filter" style={{position: "relative"}}>
            <Icon
              icon="filter"
              style={{ fontSize: "18px", verticalAlign: "middle", marginLeft: "10px" }}
              onClick={(e) => this.handleFilterButtonClick()}
              tooltip={{
                message: "Filter",
                className: "info-tooltip wide tooltip-center",
                variant: "dark",
                place: "bottom",
                id: "share-report",
              }}
            />
            {this.state.filteredMetrics.length > 0 && <Icon
              icon="check_circle"
              style={{color: "green", fontSize: "20px", position: "absolute", left: "25px", top: "-10px"}}
            />}
        </span>
       </div>
      <div className="gauge-scales-popup-button">
        <button className="close-button" onClick={(e) => this.handleGaugePopupCloseClick(e)}>Close</button>
      </div>
      <div className="gauge-scale-heading-container">
        <div
          className="gauge-scale-header"
        >
					<span>
						Performance Benchmark
					</span>
					<Icon
						icon="info"
						style={{marginLeft: "10px", fontSize: "16px"}}
						tooltip={{
							messageElement: PerformanceBenchmarkInfoElement,
							className: "info-tooltip wide",
							variant: "dark",
							place: "bottom",
							id: "performance-menter-1",
					}}/>
        </div>
      </div>
      <div>
        <ul className="popup-tabular-menu">
          <li
						style={{borderTopLeftRadius: "1rem", borderBottomLeftRadius: "1rem"}}
						className={`${this.state.duration === BenchmarkDuration.TODAY_LIVE ? "active-tab" : ""}`}
						id="performance-benchmark-duration-today"
						data-tooltip-id={"performance-benchmark-duration-today"}
						data-tooltip-content={"Today"}
						onClick={e => this.handleDurationTabClick(BenchmarkDuration.TODAY_LIVE)}
					>
						<span>
							1 day
						<Tooltips
							id={"performance-benchmark-duration-today"}
							place="right"
							variant="dark"
						/>
						</span>
					</li>
					<li
						className={`${this.state.duration === BenchmarkDuration.LAST_7_DAYS ? "active-tab" : ""}`}
						id="performance-benchmark-duration-last-seven-days"
						data-tooltip-id={"performance-benchmark-duration-last-seven-days"}
						data-tooltip-content={"Last seven days including today"}
						onClick={e => this.handleDurationTabClick(BenchmarkDuration.LAST_7_DAYS)}
					>
						<span>
								7 days
							<Tooltips
								id={"performance-benchmark-duration-last-seven-days"}
								place="left"
								variant="dark"
							/>
							</span>
					</li>
					<li
						className={`${this.state.duration === BenchmarkDuration.LAST_30_DAYS ? "active-tab" : ""}`}
						id="performance-benchmark-duration-last-thirty-days"
						data-tooltip-id={"performance-benchmark-duration-last-thirty-days"}
						data-tooltip-content={"Last thirty days including today"}
						onClick={e => this.handleDurationTabClick(BenchmarkDuration.LAST_30_DAYS)}
					>
						<span>
								30 days
							<Tooltips
								id={"performance-benchmark-duration-last-thirty-days"}
								place="left"
								variant="dark"
							/>
							</span>
					</li>
          <li
						className={`${this.state.duration === BenchmarkDuration.LAST_90_DAYS ? "active-tab" : ""}`}
						id="performance-benchmark-duration-last-ninety-days"
						data-tooltip-id={"performance-benchmark-duration-last-ninety-days"}
						data-tooltip-content={"Last ninety days including today"}
						onClick={e => this.handleDurationTabClick(BenchmarkDuration.LAST_90_DAYS)}
					>
						<span>
								90 days
							<Tooltips
								id={"performance-benchmark-duration-last-ninety-days"}
								place="left"
								variant="dark"
							/>
							</span>
					</li>
          <li
						className={`${this.state.duration === BenchmarkDuration.LAST_180_DAYS ? "active-tab" : ""}`}
						style={{borderTopRightRadius: "1rem", borderBottomRightRadius: "1rem"}}
						id="performance-benchmark-duration-last-one-eighty-days"
						data-tooltip-id={"performance-benchmark-duration-last-one-eighty-days"}
						data-tooltip-content={"Last one hundered eighty including today"}
						onClick={e => this.handleDurationTabClick(BenchmarkDuration.LAST_180_DAYS)}
					>
						<span>
								180 days
							<Tooltips
								id={"performance-benchmark-duration-last-one-eighty-days"}
								place="left"
								variant="dark"
							/>
							</span>
					</li>
        </ul>
      </div>
    </React.Fragment>
  }

  getMetricTitle(gaugeChartOptions): React.ReactNode {
		return (
			<div className="gauge-scale-metric-title">
        <span style={{marginLeft: "-10px"}}>{gaugeChartOptions.shortTitle}</span>
				<div style={{ position: "absolute", top: 10, right: 10, width: "100%", textAlign: "right" }}>
					<Icon
					icon="info"
					style={{ marginLeft: "10px", textAlign: "left" }}
					tooltip={{
						id: `performance-meter-description-${gaugeChartOptions.alias}`,
						className: "info-tooltip wide",
						message: gaugeChartOptions.description,
						place: "left",
						variant: "dark",
					}}
					/>
				</div>
      </div>
		);
  }

  getPerformanceResultTitle=(gaugeChartOptions: GaugeChartOptions) =>{
		let title = "You could do better!";
		let color = "#F5CECC";
		if(gaugeChartOptions.value > 50 && gaugeChartOptions.value <= 75) {
			title = "Good job!";
			color = "#ffbe6f"
		} else if(gaugeChartOptions.value > 75 && gaugeChartOptions.value <= 100) {
			title = "Excellent job!";
			color = "#AEF6AD"
		}

		return ( <div className="performance-result" style={{backgroundColor:color}}>
				{title}
			</div>
		)
  }

	getGaugeScaleOverallPerformance(gaugeChartOptions: GaugeChartOptions): React.ReactNode {
		return (
			<React.Fragment>
				<div style={{position: "relative", top: "-75px", backgroundColor: "white"}}>
					<div className="benchmark-value-wrapper">
						<span
							id="performance-benchmark-value-description"
							data-tooltip-id={"performance-benchmark-value-description"}
							data-tooltip-content={"This represents your organization's benchmark value and it is calculated by comparing your results with those of leading organizations, presented as a percentage value from 0% (low) to 100% (excellent)."} >
              <span>{`${gaugeChartOptions.value}`}</span>
              <span style={{fontSize: "25px"}}>%</span>
							<Tooltips
									className="info-tooltip wide"
									id="performance-benchmark-value-description"
									place="bottom"
									variant="dark" />
							</span>
					</div>
					{this.getPerformanceResultTitle(gaugeChartOptions)}
				</div>
			</React.Fragment>
		)
	}

	getGaugeScaleStats(gaugeChartOptions: GaugeChartOptions): React.ReactNode {
		return (
			<div className="gauge-scales-stats">
				<table className="performance-stats">
					<tbody>
            <tr>
							<td>
								<span>Current: </span>
								<Icon icon="info" tooltip={{
										id: `performance-meter-${gaugeChartOptions.alias}-current-value`,
										message: `This is your current performance value for the selected metric.`,
										className: "info-tooltip wide",
										variant: "dark",
								}}/>
							</td>
							<td>{gaugeChartOptions.currentValue}{gaugeChartOptions.unit}</td>
						</tr>
            <tr>
							<td>
								<span>Leading: </span>
								<Icon icon="info" tooltip={{
										id: `performance-meter-${gaugeChartOptions.alias}-leading-value`,
										message: ` This is the highest performance value achieved by any organization.`,
										className: "info-tooltip wide",
										variant: "dark",
								}}/>
							</td>
							<td>{gaugeChartOptions.benchmarked}{gaugeChartOptions.unit}</td>
						</tr>
						<tr>
							<td>
								<span>Average: </span>
								<Icon icon="info" tooltip={{
										id: `performance-meter-${gaugeChartOptions.alias}-average-value`,
										message: `This is the average performance value achieved by all organizations.`,
										className: "info-tooltip wide",
										variant: "dark",
								}}/>
							</td>
							<td>{gaugeChartOptions.average} </td>
						</tr>
						<tr style={{borderBottom: "1px dotted #b5b3b3", borderTop: "1px dotted #b5b3b3"}}>
							<td style={{padding: "18px 9px"}}>
								<span>Trends: </span>
								<Icon icon="info" tooltip={{
										id: `performance-meter-${gaugeChartOptions.alias}-trend-value`,
										message: `This represents the trend in your organizations benchmark value compared to the previous period.`,
										className: "info-tooltip wide",
										variant: "dark",
								}} />
							</td>
							<td>
								{this.getTrendInfo(gaugeChartOptions.trend)}
							</td>
						</tr>
						{this.state.duration !== BenchmarkDuration.TODAY_LIVE && <tr style={{textAlign:"center"}}>
              <td colSpan={2}>
                {this.getTrendLine(gaugeChartOptions)}
              </td>
						</tr>}
					</tbody>
				</table>
			</div>
		);
	}

	getNoDataLabel(gaugeStats: GaugeStats): React.ReactNode {
		return gaugeStats.rowsAnalyzed === 0 && <div className="no-data-label-container">
			<div className="no-data-label">No data available</div>
		</div>
	}

  getConnectelOwnershipFooter(): React.ReactNode{
    return <React.Fragment>
      <div className="ownership-footer" ref={this.footer}>
        <img style={{height: "20px"}} src={motion} />
        <div className="ownership-footer-text">Powered by Connectel</div>
      </div>
      </React.Fragment>
  }

	getTrendsGraph(): React.ReactNode {
		return this.state.metricTrendsDataPoints?.length > 0 && <div className="trends-graph-container"
		>
			<Icon
				icon="close"
				style={{fontSize: "25px", position: "absolute", right: "10px", top: "10px", color: "black", zIndex: "3"}}
				onClick={e => this.handleTrendsChartCloseClick(e)}/>
			<Chart
				style={{margin: "0px"}}
				graph={this.getTrendsGraphOptions()}
				handleEditGraphClick={null}
			/>
			<div className="trends-graph-container-footer">This represent a trendline graph with your organizations current value for each date.</div>
		</div>
	}

	getFooter(): React.ReactNode {
		return (
			<div className="performance-popup-footer">
				<Icon icon="info" style={{marginRight: "5px"}}/>
				<span>
					Data is updated every 30-minute interval
				</span>
			</div>
		);
	}

  getSharePopup(): React.ReactNode {
    return this.state.showSharePopup && <div className="share-popup">
      <input
        type="email"
        value={this.state.emails}
        onChange={e => this.setState({emails: e.target.value.split(",")})}
        placeholder="Enter the email addresses of the recipients, separated by commas"
      />
      <button onClick={e => this.handleShareSubmitButtonClick()} ref={this.shareButton}>Share</button>
      <span className="close-icon" onClick={_ => this.setState({showSharePopup: false})}>
        X
      </span>
     </div>
	}

  getFilterPopup(): React.ReactNode {
    const aliasesByCategory = this.getGroupedMetricAliasesByCategory();
    return this.state.showFilterPopup && <div className="settings-popup">
      <FormControl sx={{ width: "82%", height: "37px" }}>
				<InputLabel sx={{marginTop: "-6px"}} htmlFor="grouped-select">Select performance benchmark metrics</InputLabel>
				<Select
					multiple
          size={"small"}
					value={this.state.filteredMetrics}
					onChange={(e) => this.handleFilterChange(e)}
          onClick={e => this.handleFilterCategoryClick(e)}
					label="Grouping"
					inputProps={{
						id: 'grouped-select',
					}}
				>
					{Object.keys(aliasesByCategory).map((category) => {
            const elements = []
            elements.push(<ListSubheader style={{cursor: "pointer"}}>{category}</ListSubheader>);

            {aliasesByCategory[category].forEach(alias => {
              const gaugeChartOptions = this.getGaugeScaleOptionsForMetric(alias);
              elements.push(
                <MenuItem key={alias} value={alias}>
                  {gaugeChartOptions.shortTitle}
                </MenuItem>
              )
            })}

            return elements;
          })}
				</Select>
			</FormControl>
      <button className="" onClick={e => this.handleFilterApplyClick(this.state.filteredMetrics)}>Apply</button>
      <span className="close-icon" id="clear-filter" style={{right: "35px", width: "40px"}} onClick={e => this.handleFilterClearButtonClick(e)}>
        <Icon icon="bin2" style={{fontSize: "15px"}} tooltip={{
          message: "Clear",
          className: "info-tooltip wide tooltip-center",
          variant: "dark",
          place: "top",
          id: "clear-filter",
        }}/>
      </span>
			<span className="close-icon" onClick={_ => this.setState({ showFilterPopup: false })}>X</span>
    </div>
  }

  getGroupedMetricAliasesByCategory(): Record<string, string[]> {
    const groupedAliases: Record<string, string[]> = {};

    BenchmarkMetrics.forEach((benchmarkMetric) => {
      const category = this.getCategoryForAlias(benchmarkMetric.alias);
      groupedAliases[category] = groupedAliases[category] || [];
      groupedAliases[category].push(benchmarkMetric.alias)
    });

    return groupedAliases;
  }

  getCategoryForAlias(alias: string): string {
    if(['pause_rate', 'V_ready_rate', 'V_utilization_rate', 'V_full_utilization_rate'].includes(alias)) {
      return "Productivity";
    } else if (alias.startsWith("M_"))  {
      return "Email";
    } else if(alias.startsWith("C_")) {
      return "Chat";
    } else if(alias.startsWith("V_")) {
      return "Voice";
    } else if(alias.startsWith("VB_")) {
      return "VoiceBot";
    }
  }

	getGaugeScaleOptionsForMetric(metricAlias: string): GaugeChartOptions {
		const gaugeStats: GaugeStats =
			this.state.gaugeMeterValuesMap[metricAlias];
		const benchmarkMetric = BenchmarkMetrics.find(
			(bm) => bm.alias === metricAlias
		);

		return {
			alias: metricAlias,
			value: gaugeStats.percentage,
			average: `${gaugeStats.average}${benchmarkMetric.unit}`,
			trend: gaugeStats.trend,
			title: benchmarkMetric.title,
			shortTitle: benchmarkMetric.shortTitle,
			plotDirection: GaugeDirection.DEFAULT,
      unit: benchmarkMetric.unit,
      currentValue: gaugeStats.currentValue,
      benchmarked: gaugeStats.benchmarked,
			description: benchmarkMetric.description,
		};
	}

	async getGaugeScalesValues(
		id: string,
		duration: BenchmarkDuration
	): Promise<Record<string, GaugeStats>> {
		try {
			const gaugeValues = {};
			const metrics = BenchmarkMetrics.map(
				(benchmarkMetric) => benchmarkMetric.alias
			);
			const response = await getGaugeData(id, metrics, duration);

			response.benchmarkData?.forEach((benchmarkData) => {
				let percentage = 0;
				const previousValue = benchmarkData.previousValue;
				const currentValue = benchmarkData.current;
				const highestValue = benchmarkData.benchmarked;
				const direction = this.getPlotBandDirection(
					benchmarkData
				);
				switch (direction) {
					case GaugeDirection.DEFAULT:
						percentage = Math.floor(
							(currentValue / highestValue) * 100
						);
						percentage =
							highestValue === currentValue && highestValue === 0
								? 100
								: percentage;
						break;
					case GaugeDirection.REVERSE:
						percentage = Math.floor(
							(highestValue / currentValue) * 100
						);
						percentage =
							(highestValue === currentValue && highestValue === 0) ||
							(currentValue === 0 && highestValue !== 0)
								? 100
								: percentage;
						percentage = percentage === Infinity ? 0 : percentage;
						break;
				}

				gaugeValues[benchmarkData.metricAlias] = {
          currentValue: currentValue,
          benchmarked: highestValue,
					percentage: percentage || 0,
					average: benchmarkData.average,
					trend: this.calculateTrend(previousValue, currentValue),
					rowsAnalyzed: benchmarkData.rowsAnalyzed
				};
			});

			return gaugeValues;
		} catch (e) {
			return {};
		}
	}

	getTrendInfo(trend: string): React.ReactNode {
		const positive = !trend.includes("-");
		const icon = positive ? "arrow-up-right2" : "arrow-down-right2";
		const iconColor = positive ? "#39C6A2" : "#DA5E5E";

		return (
			<div>
				<Icon
					icon={`${icon}`}
					style={{ color: iconColor, fontSize: "15px" }}
				/>
				<span style={{ paddingTop: "10px", color: iconColor }}> {trend}%</span>
			</div>
		);
	}

	getTrendLine(gaugeChartOptions: GaugeChartOptions): React.ReactNode {
		return (
				<button className="trendline-button"
					style={{cursor: "pointer"}}
					onClick={e => this.getTrendsChart(gaugeChartOptions.alias, gaugeChartOptions.shortTitle)}
				>
					<Icon
						icon={`stats-dots`}
						style={{ fontSize: "15px" }}
					/>
					Show Trendline
				</button>
		);
	}

	async getTrendsChart(metricAlias: string, shortTitle: string) {
		const response = await getGaugeTrendsData(this.getNodeId(), metricAlias, this.state.duration)
		this.setState({metricTrendsDataPoints: response.data.data.dataPoints, metricTrendsShortTitle: shortTitle})
	}

	getTrendsGraphOptions() {
		const seriesData = this.state.metricTrendsDataPoints.map(point => [point.time, point.value]);
		return {
			chart: {
				type: "spline",
			},
			title: {
				text: this.state.metricTrendsShortTitle,
				align: 'center'
			},
			yAxis: {
				max: 100,
				title: {
					text: this.state.metricTrendsShortTitle
				}
			},
			xAxis: {
				accessibility: {
					rangeDescription: 'Answered calls'
				},
				labels: {
					rotation: -45,
					enabled: true,
					formatter: function() { return seriesData[this.value][0];},
				}
			},
			series: [{
				color: "rgb(144, 237, 125)",
				name: `Current value`,
				data: seriesData
			}],
			credits: {
				enabled: false
			},
		}
	}

	calculateTrend(previousValue: number, currentValue: number): string {
		if (previousValue === currentValue) {
			return "0";
		} else {
			const trend = Math.floor(
				((currentValue - previousValue) / previousValue) * 100
			);
			return (trend === Infinity ? 0 : trend).toString();
		}
	}

	getPlotBandDirection(metric: BenchmarkMetricResponse): GaugeDirection {
		switch (metric.queryType) {
			case BenchmarkQueryType.Min:
				return GaugeDirection.REVERSE;
		}

		return GaugeDirection.DEFAULT;
	}

	getNodeId(): string {
		return (
			this.getWidgetHostId() || this.state.flattenedNodes?.[0].id
		).toString();
	}

	getWidgetHostId(): number {
		if (this.props.nodes?.widgets_host) {
			const node = this.state.flattenedNodes.find(
				(node) => node.id === parseInt(this.props.nodes.widgets_host)
			);
			return node?.id || 0;
		}

		return null;
	}

	getResetScaleValues(): Record<string, GaugeStats> {
		const gaugeMeterValuesMap: Record<string, GaugeStats> = {}
		BenchmarkMetrics.forEach(
			(benchmarkMetric) => {
				gaugeMeterValuesMap[benchmarkMetric.alias] = {
					currentValue: 0,
					benchmarked: 0,
					percentage: 0,
					average: "0",
					trend: "0",
					rowsAnalyzed: 0
				}
			}
		);
		return gaugeMeterValuesMap;
	}

	isPerformanceIconVisible(): boolean {
		const widgets_host = this.getWidgetHostId();
		const userRole = getItem().user.role_id;
		const defaultValidation = (Object.keys(this.props.nodes || {}).length > 0 || !!widgets_host) && this.props.nodes?.benchmark
		if(userRole === 1)
			return defaultValidation;
		else
			return this.props.nodes?.benchmark_visibility?.includes(getItem().user.role_id) && defaultValidation;
	}

  async handleShareButtonClick() {
    this.setState({
      showSharePopup: true
    })
  }

  async handleShareSubmitButtonClick(){
    const dataURL = await this.createCanvas()
    this.shareButton.current.disabled = true;

    this.setState({
      showSharePopup: false
    })

    sendPerformanceBenckmarkMail({
      duration: this.state.duration,
      to: this.state.emails,
      attachment: {
        filename: "PerformanceBenchmark.png",
        dataURL
      }
    }).then(response => this.props.onSuccess({message: "Performance report shared successfully."}))
    .catch(err => this.props.onError({message: "Failed to share performance report"}))

  }

  handleFilterCategoryClick(event) {
    const category = event.target.innerText;
    const categories = this.getGroupedMetricAliasesByCategory();
    if(categories[category]) {

      let filteredMetrics = [];
      const alreadySelectedMetrics = this.state.filteredMetrics;
      let categoryAlreadySelected = false;
      categories[category].forEach(metricAlias => {
        if(alreadySelectedMetrics.includes(metricAlias)) {
          categoryAlreadySelected = true;
        }
      })

      if(categoryAlreadySelected) {
        filteredMetrics = alreadySelectedMetrics.filter(metricAlias => !categories[category].includes(metricAlias))
      } else {
        filteredMetrics = [...new Set([...this.state.filteredMetrics, ...categories[category]])];
      }

      this.setState({
        filteredMetrics: [...filteredMetrics]
      })
    }
  }

  handleFilterClearButtonClick(event) {
    this.setState({
      filteredMetrics: []
    })
  }

  handleFilterChange(event) {
    this.setState({ filteredMetrics: event.target.value })
  }

	async handleFilterButtonClick() {
		this.setState({
			showFilterPopup: true
		})
	}

	async handleFilterApplyClick(metrics: string[]) {
		localStorage.setItem(BenchmarkMetricsStorageKey, JSON.stringify(metrics || []))

		this.setState({
			showFilterPopup: false
		})
	}

	handleTrendsChartCloseClick(e) {
		this.setState({metricTrendsDataPoints: []})
	}

	handleScrollPointerVisibility(element: HTMLDivElement) {
		const isVisible = element && element.scrollHeight > element.clientHeight
		if(element && this.state.showScrollPointer !== isVisible) {
			this.setState({showScrollPointer: isVisible})
		}
	}

	handleDurationTabClick(duration: BenchmarkDuration) {
		if(this.state.duration !== duration) {
			this.getAndSetGaugeScalesValues(duration)
		}
	}

	async handlePerformanceIconClick(e) {
		let gaugeMeterValuesMap = {};
		if (!this.state.showGaugeMeter) {
			this.freezeBody();
			gaugeMeterValuesMap = await this.getGaugeScalesValues(
				this.getNodeId(),
				this.state.duration
			);
		}

		this.setState({
			showGaugeMeter: !this.state.showGaugeMeter,
			gaugeMeterValuesMap,
		});
	}

  async handleGaugePopupCloseClick(e) {
		this.freezeBody(false)
    this.setState({
      emails: [],
      showSharePopup: false,
      showGaugeMeter: !this.state.showGaugeMeter,
	    showFilterPopup: false,
    });
  }

	async handleGaugesRefreshClick(e) {
		if (this.state.showGaugeMeter) {
			this.getAndSetGaugeScalesValues(this.state.duration)
		}
	}

  async createCanvas() {
    const width = this.scalesContainer.current.style.width;
    const height = this.scalesContainer.current.style.height;
    this.scalesContainer.current.style.width = "2400px";
    this.scalesContainer.current.style.height = "1500px";
    this.footer.current.style.bottom = "0px";
    this.footer.current.style.top = "1500px";

    const canvas = await html2canvas(this.scalesContainer.current);

    this.scalesContainer.current.style.width = width;
    this.scalesContainer.current.style.height = height;
    this.footer.current.style.top = null;
    this.footer.current.style.bottom = "20px";

    return canvas.toDataURL();
  }

	async getAndSetGaugeScalesValues(duration: BenchmarkDuration) {
		const gaugeMeterValuesMap = await this.getGaugeScalesValues(
			this.getNodeId(),
			duration
		);
		this.setState({
			gaugeMeterValuesMap,
			duration,
			lastFetchedTime: Date.now()
		})
	}

	freezeBody(freeze: boolean = true) {
		if(freeze) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "";
		}
	}
}

const mapStateToProps = (state: State) => ({
	nodes: state.nodes,
});

export default connect(mapStateToProps, {onSuccess, onError})(Performance);
