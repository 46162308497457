import React from "react";
import { State } from "../../store";
import { connect } from "react-redux";
import "../../assets/styles/loading.css";
import { setPopup, unsetPopup } from "../../reducers/popup";
import { SessionTimeout, Statistics } from "./Components";
import { PopupPropsType } from "../../utilities";
interface PopupProps {
  popup: any;
}

class Popup extends React.Component<PopupProps> {
  render() {
    return this.props.popup.type !== "" ? (
      <div className="loading">
        {this.props.popup.type === PopupPropsType.SESSION_TIMEOUT ? (
          <SessionTimeout />
        ) : (
          this.props.popup.type === PopupPropsType.STATISTIC_MESSAGE && (
            <Statistics message={this.props.popup.message} />
          )
        )}
      </div>
    ) : (
      <></>
    );
  }
}
const mapStateToProps = (state: State) => ({ popup: state.popup });

export default connect(mapStateToProps, { setPopup, unsetPopup })(Popup);
