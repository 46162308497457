import { Component } from "react";
import { whiteLogo } from "../assets/images";
import "../assets/styles/header.css";
import { getItem, userDropdownOptions } from "../utilities";
import Icon from "../utilities/Icons";
import { connect } from "react-redux";
import { signOut } from "../reducers";
import Notifications from "./Notifications";
import React from "react";
import Performance from "./Performance";

interface HeaderProps {
  signOut: Function;
}

interface HeaderState {
  isUserDropdownExpanded: boolean;
  username: string;
}

class Header extends Component<HeaderProps, HeaderState> {
  user = getItem().user;

  constructor(props: HeaderProps) {
    super(props);

    const user = getItem().user;
    this.state = {
      username: user?.username ?? "",
      isUserDropdownExpanded: false,
    };
  }

  render() {
    return (
      <div className="header-main">
        <img className="imgHeader" src={whiteLogo} alt="" />
        <ul className="header-list">
          {this.getHeaderIcons()}
          <li className="list-icon clickable">
            {" "}
            <div
              className={"profile"}
              onClick={() =>
                this.setState({
                  isUserDropdownExpanded: !this.state.isUserDropdownExpanded,
                })
              }
              tabIndex={1}
              onBlur={() => {
                this.setState({ isUserDropdownExpanded: false });
              }}
            >
              <Icon icon="user" className="list-icon-user" />
              <div className="header-user-name">{this.user.name}</div>
              <Icon
                icon={
                  this.state.isUserDropdownExpanded
                    ? "keyboard_arrow_up"
                    : "keyboard_arrow_down"
                }
                className="icon-arrow"
              />
              {this.state.isUserDropdownExpanded && (
                <div id="profile-dd" className="profile-menu">
                  {userDropdownOptions.map(({ onClick, title, children }) => (
                    <div
                      key={title}
                      onClick={
                        title === "Sign out"
                          ? () => this.props.signOut()
                          : onClick
                      }
                      title={title}
                      className="notification-card profile-card"
                    >
                      {children}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </li>
        </ul>
      </div>
    );
  }

  getHeaderIcons(): React.ReactNode {
    return <React.Fragment>
      <Performance />
      <li className="list-icon">
        <Icon
          icon="cloud_done"
          onClick={() => window.open("https://status.connectel.io/", "_blank")}
        />
      </li>
      <li className="list-icon">
        <Icon
          icon="help"
          onClick={() =>
            window.open("https://helpdesk.connectel.io/support/home", "_blank")
          }
        />
      </li>
      <li className="list-icon">
        <Notifications />
      </li>
    </React.Fragment>
  }
}
export default connect(undefined, {
  signOut,
})(Header);
