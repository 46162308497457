import React from "react";
import { connect } from "react-redux";
import { unsetPopup } from "../../../reducers";
import "../../../assets/styles/sessionTimeout.css";
import { activateStatistics } from "../../../interfaces";
interface StatisticsProps {
  unsetPopup: Function;
  message: string;
}
const activateStatisticsMessage = {
  [activateStatistics.AGENT]:
    "This configuration is only available for role admin.",
  [activateStatistics.NO_HOST]:
    "Go to Portal and configure widgets host to view statistics on landing page.",
  [activateStatistics.NO_LICENSE]:
    "Purchase skyview and insights license to use this configuration.",
};
class Statistics extends React.Component<StatisticsProps> {
  render() {
    return (
      <div className="Session-timeout-box">
        <div className="Session-timeout-header">
          {activateStatisticsMessage[this.props.message]}{" "}
          <a
            href="https://helpdesk.connectel.io/support/solutions/articles/103000054289-how-to-add-statistics-to-the-landing-page"
            target="_blank"
          >
            Read more
          </a>
        </div>
        <div className="Session-timeout-btn btn" onClick={this.close}>
          {" "}
          Close
        </div>
      </div>
    );
  }

  close = () => {
    this.props.unsetPopup();
  };
}

export default connect(undefined, { unsetPopup })(Statistics);
