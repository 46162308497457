import { createSlice } from "@reduxjs/toolkit";

const loadingSlice = createSlice({
  name: "loading",
  initialState: 0,
  reducers: {
    setLoading: (state) => {
      const value = state + 1;
      return value;
    },
    unsetLoading: (state) => {
      const value = state > 0 ? state - 1: 0;
      return value;
    },
  },
});

// destructure actions and reducer from the slice (or you can access as loadingSlice.actions)
const { actions, reducer } = loadingSlice;

// export individual action creator functions
export const { setLoading, unsetLoading } = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
