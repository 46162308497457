import React, { Component } from "react";
import { HTMLevent } from "../../interfaces";
import Tooltip, { ITooltip } from "../Tooltips";
import "../../assets/Icomoon/style.css";

export default class Icon extends Component<{
  icon: string;
  onMouseOver?: HTMLevent;
  onMouseLeave?: HTMLevent;
  style?: React.CSSProperties;
  size?: number;
  tooltip?: ITooltip;
  onClick?: HTMLevent;
  className?: string;
  hoverTitle?: string;
}> {
  public render(): React.ReactNode {
    if (this.props.tooltip)
      return (
        <span
          id={this.props.tooltip && this.props.tooltip.id}
          className={`icon icon-${this.props.icon}${
            this.props.onClick ? " clickable" : ""
          }${this.props.className ? ` ${this.props.className}` : ""}`}
          style={this.props.style}
          onClick={this.props.onClick}
          data-tooltip-id={this.props.tooltip && this.props.tooltip.id}
          data-tooltip-content={this.props.tooltip && this.props.tooltip.message}
        >
          {this.props.tooltip && <Tooltip {...this.props.tooltip} />}
        </span>
      );
    return (
      <span
        className={`icon icon-${this.props.icon} ${
          this.props.onClick ? " clickable" : ""
        }${this.props.className ? ` ${this.props.className}` : ""}`}
        style={this.props.style}
        onClick={this.props.onClick}
        onMouseOver={this.props.onMouseOver}
        onMouseLeave={this.props.onMouseLeave}
        title={this.props.hoverTitle || ""}
      />
    );
  }
}
