import { combineReducers, configureStore } from "@reduxjs/toolkit";
import LoadingReducer from "../reducers/loading";
import SnackbarReducer from "../reducers/snackbar";
import PopupReducer from "../reducers/popup";
import NodeReducer from "../reducers/nodeList";
import loginSlice from "../reducers/login";
import notificationsSlice from "../reducers/notification";
const reducer = combineReducers({
  isLoading: LoadingReducer,
  snackbar: SnackbarReducer,
  popup: PopupReducer,
  nodes: NodeReducer,
  pageType: loginSlice,
  notifications: notificationsSlice,
});

// create the store from the combined reducer
const store = configureStore({ reducer });

export default store;

// typescript type for the combined state
export type State = ReturnType<typeof reducer>;
