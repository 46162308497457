import React, { Component } from "react";
import { connect } from "react-redux";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Login from "./components/Home";
import SamlAuth from "./components/SamlAuth";
import Services from "./components/Services";
import "./index.css";
import { State } from "./store";
import { handleRedirections, handleReferrer } from "./utilities";
import { onError, setPopup } from "./reducers";

class Route extends Component<{ pageType: any; setPopup: any; onError: any }> {
  unAuthenticateRouter = createBrowserRouter([
    {
      path: "/",
      element: <Login />,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/saml/authenticate",
      element: <SamlAuth/>,
    },
  ]);

  authenticateRouter = createBrowserRouter([
    {
      path: "/",
      element: <Services />,
      errorElement: <React.Fragment />,
    },
  ]);

  render(): React.ReactNode {
    handleReferrer();
    handleRedirections(
      this.props.pageType,
      (url) => (window.location.href = url)
    );
    return (
      <RouterProvider
        router={
          this.props.pageType === "login"
            ? this.unAuthenticateRouter
            : this.authenticateRouter
        }
      />
    );
  }
}

const mapStateToProps = (state: State) => ({
  pageType: state.pageType.loginState,
});

export default connect(mapStateToProps, { setPopup, onError })(Route);
