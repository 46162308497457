import { LoginRequest } from "./login.interfaces";
import { TwoFactorAuthRequest } from "./twoFactorAuthenticate.interfaces";
import { ForgotPasswordAuthRequest } from "./forgotPassword.interfaces";
import NotificationPropsTypes from "./notification.interfaces";
type HTMLevent = (event?: any) => void;
type HTMLElementEventHandler = (
  key: string,
  value: any,
  index?: number
) => void;

export enum activateStatistics {
  AGENT = "agent",
  NO_LICENSE = "no license",
  NO_HOST = "no host",
  PERFECT = "perfect",
}

export * from "./cookie.interfaces";
export * from "./snackbar.interfaces";
export * from "./nodeList.interfaces";
export * from "./popup.interfaces";

export type {
  LoginRequest,
  TwoFactorAuthRequest,
  ForgotPasswordAuthRequest,
  HTMLevent,
  HTMLElementEventHandler,
  NotificationPropsTypes,
};
