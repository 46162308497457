import { Component } from "react";
import { connect } from "react-redux";
import { setServices, PageRoute, onSuccess } from "../reducers";
import { samlLogin } from "../services";
import { handleRedirections, setItem } from "../utilities";

class samlAuth extends Component<{
	setServices: Function;
	onSuccess: Function;
}> {

	render(): any {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);
		const token = urlParams.get("token");
		this.authenticateUser(token);
	}

  onSuccessfulOf2FASubmit = (response: any) => {
		if (response.data.tokens) {
			this.handleSuccessfulLogin();
		}
		setItem(response.data);
	};

	handleSuccessfulLogin() {
		this.props.setServices();
		handleRedirections(
			PageRoute.SERVICES,
			(url) => (window.location.href = url)
		);
	}

	async authenticateUser(token: string) {
		const response = await samlLogin({
			token: token,
      host: window.location.origin
		});
		this.onSuccessfulOf2FASubmit(response.data);
		this.props.onSuccess({ message: "Successfully Login" });
	}
}

export default connect(undefined, { setServices, onSuccess })(samlAuth);
