import axios from "axios";
import { AxiosRequestConfig } from "axios";
import config from "../config";
import { getItem } from "../utilities";

const PortalInstance = axios.create({
  baseURL: config.portal,
});

PortalInstance.interceptors.request.use((config: AxiosRequestConfig) => {
  const tokens = getItem().tokens.portal;
  if (tokens !== null) {
    config.headers!.Authorization = `Bearer ${tokens}`;
  }
  return config;
});

export default PortalInstance;
