import axios, { AxiosRequestConfig } from "axios";
import config from "../config";
import { getItem } from "../utilities";

const getUserStatistics = async (url) => {
  if(!url) return;
  const coreInstance = axios.create({
    baseURL: `${url}${config.corePath}`,
  });

  coreInstance && coreInstance.interceptors.request.use((config: AxiosRequestConfig) => {
    const tokens = getItem().tokens.insights;
    if (tokens !== null) {
      config.headers!.Authorization = `Bearer ${tokens}`;
    }
    return config;
  });
  return await coreInstance({
    method: "GET",
    url: "/report/0/widget/0/data?license=insights",
  });
};
export { getUserStatistics };
