import { NavigateFunction } from "react-router-dom";
import config from "../config";
import { License } from "../interfaces/license";
import { PageRoute } from "../reducers";
import { signOutService } from "../services";
import { RoleType } from "./constants";
import { getItem, removeItem } from "./cookie";
import { IsJWTExpired } from "./jwt";
import { setCookie } from "typescript-cookie";

const flattenNodes = (organizations: any[], nodes_flatten: any[]) => {
  organizations.forEach((organization) => {
    organization.nodes.forEach((node: any) => {
      let value = { ...node };
      value.insights = organization.insights_license;
      value.skyview = organization.skyview_license;
      value.has_external_domain = organization.has_external_domain;
      value.organization_survey_license = organization.survey_license;
      nodes_flatten.push(value);
    });
    if (Array.isArray(organization.organizations))
      flattenNodes(organization.organizations, nodes_flatten);
  });
  return nodes_flatten;
};

const getServiceUrl = (
  type: License,
  host: any,
  name: any,
  eviexa?: string | undefined,
  isExternal: boolean = false,
): string => {
  let tokens = JSON.parse(localStorage.getItem("tokens") ?? "{}");
  let user = JSON.parse(localStorage.getItem("user") ?? "{}");
  !isExternal && generateSSOCookie(type, tokens, user);
  switch (type.toLowerCase()) {
    case License.SKYVIEW:
      return isExternal ? `${host}skyview?token=${tokens[type]}&username=${user["username"]}` : `${host}skyview`;
    case License.INSIGHTS:
      return isExternal ? `${host}insights?token=${tokens[type]}&username=${user["username"]}` : `${host}insights`;
    case License.SURVEY:
      return isExternal ? `https://survey.connectel.io/${eviexa}/index/login?token=${tokens[type]}` : `https://survey.connectel.io/${eviexa}/index/login`;
    case License.PORTAL:
      return isExternal ? `${config.portalUI}?token=${tokens["portal"]}&userId=${user["id"]}` : `${config.portalUI}`;
    case License.AGENT_USER:
      return `${host}login?token=${tokens["motion"]}&userId=${user.motion_user_id}`;
  }

  return "";
};

const generateSSOCookie = (license: License, tokens: Record<string, string>, user: {username?: string, id?: any}) => {
  let path = `/${license}`;
  let domain = getLoginCookieDomain();
  let cookieData = { token: tokens[license] }
  let cookieName = `${license}-token`
  switch(license){
    case License.SKYVIEW:
    case License.INSIGHTS:
      cookieData["username"] = user?.username;
      break;
    case License.SURVEY:
      path = "/"
      break;
    case License.PORTAL:
      path = "/"
      cookieData["userId"] = user?.id;
      break;
  }

    setCookie(cookieName, JSON.stringify(cookieData), {path, domain, expires: new Date(Date.now() + config.loginCookieExpiry)});
}

const redirectToUrl = (url: string, newTab: boolean = true) => {
  switch (newTab) {
    case true:
      window.open(url, "_blank");
      break;
    case false:
      window.open(url);
      break;
  }
};

const signOut = () =>
  signOutService()
    .then((_response) => {
      removeItem();
    })
    .catch((err) => {
      removeItem();
    });

const appendSurvey = (name: string) => {
  setTimeout(function () {
    const user = getItem().user;
    const eviexaContainer = document.getElementById("evx-survey-container");
    if (eviexaContainer) {
      eviexaContainer.remove();
    }
    const role = (user === null || user === void 0 ? void 0 : user.role_id)
      ? RoleType[user.role_id as keyof typeof RoleType]
      : RoleType[5];
    const containerElement = document.createElement("div");
    const spanElement = document.createElement("span");
    const scriptElement = document.createElement("script");
    spanElement.id = "evx-survey";
    containerElement.id = "evx-survey-container";
    scriptElement.src = `https://survey.connectel.io/connectel/widget/nps/19?role=${role}&organization=${name}&agent_name=${user.name}`;
    scriptElement.async = true;
    containerElement.appendChild(spanElement);
    containerElement.appendChild(scriptElement);
    document.body.appendChild(containerElement);
  }, 5000);
};

function handleReferrer() {
  const tokens = getItem()?.tokens
  const params = getUrlParams();
  const isSignout = params['signout'] === '1';
  if(document.referrer.includes("skyview") && !IsJWTExpired(tokens?.skyview)) {
    generateSSOCookie(License.SKYVIEW, tokens, {})
    window.location.href = `${document.referrer}`
  } else if(document.referrer.includes("insights") && !IsJWTExpired(tokens?.insights)) {
    generateSSOCookie(License.INSIGHTS, tokens, {})
    window.location.href = `${document.referrer}`
  } else if(document.referrer.includes("portal") && !IsJWTExpired(tokens?.portal) && !isSignout) {
    generateSSOCookie(License.PORTAL, tokens, {})
    window.location.href = `${document.referrer}`
  }
}

function handleRedirections(pageType: PageRoute, navigate: NavigateFunction) {
  let authenticatedPaths = ["/login", "/saml/authenticate"];
  if(
    pageType === PageRoute.SERVICES &&
    authenticatedPaths.includes(window.location.pathname)
  ) {
    navigate("/")
  }
}

function openEditGraphPage(url) {
  let tokens = getItem().tokens.insights;
  generateSSOCookie(License.INSIGHTS, { [License.INSIGHTS]: tokens }, {});
  window.open(`${url}/insights/settings`, "_blank");
}

function getUrlParams() {
  const params= {};
  window.location.search.substr(1, window.location.search.length - 1).split("&").forEach(p => {
    const [key, val] = p.split("=");
    params[key] = val;
  });

  return params;
}

function getLoginCookieDomain(): string {
  if(window.location.hostname.includes("xcally.com")) {
    return ".xcally.com";
  }

  return config.loginCookieDomain;
}

const checkImageValidityByUrl = async (url: string): Promise<boolean> => {
  return new Promise((resolve, reject) => {
    const img = new Image();

    img.onload = () => {
      //The image URL is valid
      resolve(true);
    };

    img.onerror = () => {
      //The image URL is not valid
      resolve(false); 
    };

    img.src = url;

    // If the image is already in the browser cache, the `load` event
    // might have been triggered before the event listener was attached.
    // Checking `img.complete` to handle this scenario.
    if (img.complete && img.naturalWidth !== 0) {
      //The image URL is valid and was loaded from cache
      resolve(true);
    }
  });
};

export {
  flattenNodes,
  getServiceUrl,
  redirectToUrl,
  signOut,
  appendSurvey,
  handleRedirections,
  handleReferrer,
  openEditGraphPage,
  getUrlParams,
  checkImageValidityByUrl
};
