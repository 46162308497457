import { Component } from "react";
import { login } from "../../services";
import "../../assets/styles/log.css";
import { PagePropsType, getItem } from "../../utilities";
import { onError, onSuccess, setLoading, unsetLoading } from "../../reducers";
import { connect } from "react-redux";
import { microsoft } from "../../assets/images";

interface LoginProps {
  onSuccessfullySubmit: Function;
  handlePageChange: Function;
  setLoading: Function;
  unsetLoading: Function;
  onSuccess: Function;
  onError: Function;
}
interface LoginState {
  domain: string;
  username: string;
  password: string;
  rememberMe: boolean;
  samlLogin: boolean;
}
class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      domain: "",
      username: "",
      password: "",
      rememberMe: false,
      samlLogin: false,
    };
  }
  componentDidMount(): void {
    let url = window.location.hostname;
    const domain = getItem().domain;
    if (!url.includes("login.")) {
      url = url.replace("login.", "");
      this.setState({ domain: domain ? domain : url });
    }
  }

  render() {
    const allowSamlLogin = window.location.origin.includes("login") ? this.state.domain?.endsWith(".connectel.io") : true;
    return (
      <div className="paper">
        <div className="welcome">Log in</div>
        <div className="greeting-log">
          👋 Welcome back! You’re almost there.
        </div>
        <form id="login-form" onSubmit={(event) => this.handleSubmit(event)}>
          <div>Domain</div>

          <input
            type="text"
            placeholder="Domain"
            name="domain"
            value={this.state.domain}
            onChange={(event) => this.onChange(event)}
          />

          <div>Username</div>

          <input
            type="text"
            placeholder="Username"
            name="username"
            value={this.state.username}
            onChange={(event) => this.onChange(event)}
          />

          <div>Password</div>

          <input
            type="password"
            placeholder="Password"
            name="password"
            value={this.state.password}
            onChange={(event) => this.onChange(event)}
          />

          <div className="bottom-bar">
            <div className="remember-me-box">
              <input
                type="checkbox"
                name="rememberMe"
                checked={this.state.rememberMe}
                onChange={(event) => this.onChange(event)}
              />
              Remember me?
            </div>
            <div
              className="forgot-password link"
              onClick={() =>
                this.props.handlePageChange(PagePropsType.FORGET_PASSWORD)
              }
            >
              Forgot password?
            </div>
          </div>
          <div className="button-wrapper">
            <button className="button" type="submit">
              Login
            </button>
            <button className={`microsoft-signin-button ${!allowSamlLogin ? "disabled" : ""}`} onClick={allowSamlLogin ? () => this.setState({ samlLogin: !this.state.samlLogin }): undefined}>
              <span className="microsoft-logo"> <img src={microsoft} alt="microsoft-image"/></span> Sign in with Microsoft
            </button>
          </div>
        </form>
        <div className="error"></div>
      </div>
    );
  }


  async handleSubmit(e) {
    const redirectDomain = window.location.origin.includes("login") && this.state.domain?.endsWith(".connectel.io") ? `https://${this.state.domain}/` : window.location.href;
    e.preventDefault();
    this.props.setLoading();
    try {
      if (this.state.samlLogin){
        window.location.href = `${redirectDomain}api/auth/saml?redirect=${redirectDomain}saml/authenticate`;
      }
      else{
      if (this.hasValidCredentials()) {
        const response = await login({
          domain: this.state.domain,
          license: null, //TODO or get from parameter
          password: this.state.password,
          remember_me: this.state.rememberMe,
          username: this.state.username,
        });

        response.data.data.domain = this.state.domain;
        response.data.data.remember_me = this.state.rememberMe ? 1 : 0;
        this.props.onSuccessfullySubmit(response.data);
        this.props.onSuccess({ message: "Successfully Login" });
      }
    }
    } catch (error: any) {
      this.props.onError({
        message: error?.response?.data.data[0].message ?? "Invalid domain",
      });
    }
    this.props.unsetLoading();
  }
  private hasValidCredentials(): boolean {
    if (this.state.username.length > 0 && this.state.password.length > 0) {
      return true;
    }
    return false;
  }
  private onChange(event: any) {
    const { value, name, checked } = event.target;
    this.setState({
      [name]: name === "rememberMe" ? checked : value,
    } as Pick<LoginState, keyof LoginState>);
  }
}

export default connect(undefined, {
  setLoading,
  unsetLoading,
  onSuccess,
  onError,
})(Login);
