import { Component } from "react";
import { License } from "../interfaces/license";
import { getServiceUrl } from "../utilities";
import "../assets/styles/sidebar.css";

interface SideBarProps {
  serviceTitle: string;
  sidebarOptions: any[];
  close: Function;
  isOpen: boolean;
  isExternal: boolean;
}

export default class SideBar extends Component<SideBarProps, {}> {
  render() {
    return (
      <>
        <div
          className={`${!this.props.isOpen ? "disabled-sidebar" : "backdrop"}`}
          onClick={() => this.props.close()}
        ></div>
        <div id="side-bar" className={`${this.props.isOpen && "active"}`}>
          <div className="header">
            <h2>Select Site</h2>
            <span>{this.props.serviceTitle}</span>
          </div>
          {this.props.sidebarOptions.map((sideBarOption, index) => (
            <span
              className={`sidebar-list-member ${
                !sideBarOption.active && "inactive"
              }`}
              key={`sidebar-${index}`}
              onClick={() => {
                window.open(
                  getServiceUrl(
                    this.props.serviceTitle as License,
                    sideBarOption.hostname,
                    sideBarOption.name,
                    sideBarOption.eviexa,
                    this.props.isExternal
                  ),
                  "_blank"
                );
              }}
            >
              {sideBarOption.name}{" "}
              {!sideBarOption.active && <span>(Inactive)</span>}
            </span>
          ))}
        </div>
      </>
    );
  }
}
