import React from "react";
import { State } from "../store";
import { connect } from "react-redux";
import { closeSnackbar } from "../reducers";
import "../assets/styles/snackbar.css";
import { snackbarPropTypes } from "../interfaces";
interface SnackBarProps {
  closeSnackbar: () => void;
  state: {
    message: string;
    variant: snackbarPropTypes;
  };
}

class SnackBar extends React.Component<SnackBarProps> {
  componentDidUpdate(): void {
    if (this.props.state.variant !== snackbarPropTypes.NO)
      setTimeout(() => this.props.closeSnackbar(), 5000);
  }
  render() {
    return this.props.state.variant !== snackbarPropTypes.NO ? (
      <div className="bottom-center">
        <div className={`snackbar ${this.props.state.variant}`}>
          <span>{this.props.state.message}</span>
          <i
            className="fa fa-close close"
            onClick={() => this.props.closeSnackbar()}
          ></i>
        </div>
      </div>
    ) : (
      <></>
    );
  }
}
const mapStateToProps = (state: State) => ({
  state: state.snackbar,
});

export default connect(mapStateToProps, { closeSnackbar })(SnackBar);
