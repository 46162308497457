import React, { Component } from "react";
import { whiteLogo } from "../assets/images/index";
import "../assets/styles/log.css";
import {
  PagePropsType,
  checkImageValidityByUrl,
  removeItem,
} from "../utilities";
import Icon from "../utilities/Icons";
import { getNodeConfig } from "../services";

interface LoginProps {
  children: React.ReactNode;
  handlePageChange: Function;
  page?: PagePropsType;
}

interface LoginState {
  isValidUrl: boolean;
  imageUrl: string;
}

class Login extends Component<LoginProps, LoginState> {
  constructor(props: LoginProps) {
    super(props);
    this.state = {
      isValidUrl: false,
      imageUrl: "",
    };
  }

  async componentDidMount() {
    try {
      const nodeConfig = await getNodeConfig();
      await this.validateImageUrl(nodeConfig.data.data.config.logo_url);
    } catch (error) {
      console.error("Failed to load and validate image URL:", error);
    }
  }

  validateImageUrl = async (url: string) => {
    const isValid = await checkImageValidityByUrl(url);
    this.setState({ isValidUrl: isValid, imageUrl: url });
  };
  render() {
    const { children, handlePageChange, page } = this.props;
    const { isValidUrl } = this.state;
    return (
      <div className="main-login-div">
        <div className="grid">
          <div
            style={{
              position: "relative",
              height: "inherit",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {[
              PagePropsType.FORGET_PASSWORD,
              PagePropsType.TWO_FACTOR_AUTH,
            ].includes(page!) && (
              <div
                className="back-button"
                onClick={() => {
                  removeItem();
                  handlePageChange(PagePropsType.LOGIN);
                }}
              >
                {" "}
                <Icon icon="keyboard_arrow_left" />
                Back
              </div>
            )}
            {children}
          </div>
          <div className="left">
            {isValidUrl ? (
              <img className="clientLogo" src={this.state.imageUrl} alt="" />
            ) : (
              <img className="loginImage" src={whiteLogo} alt="" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
