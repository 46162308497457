interface TryitProps {
  tryItLink: string;
}

export const TryItPopUp = ({ tryItLink }: TryitProps) => {
   return (
    <div className="iframe-main">
      <iframe title="tryIt" className="iframe" src={tryItLink}></iframe>
    </div>
  );
};
