import { Component } from "react";
import "../../assets/styles/log.css";
import "../../assets/styles/forgotForm.css";
import { forgotPassword } from "../../services";
import { connect } from "react-redux";
import { onError, onSuccess, setLoading, unsetLoading } from "../../reducers";
import { getItem } from "../../utilities";

interface forgotFormProps {
  handlePageChange: Function;
  setLoading: Function;
  unsetLoading: Function;
  onSuccess: Function;
  onError: Function;
}

interface ForgotPasswordState {
  domain: string;
  email: string;
}

class ForgotPassword extends Component<forgotFormProps, ForgotPasswordState> {
  constructor(props: forgotFormProps) {
    super(props);
    this.state = {
      domain: "",
      email: "",
    };
  }
  componentDidMount(): void {
    const url = window.location.hostname;
    const domain = getItem().domain;
    if (url || domain) {
      url.replace("login.", "");
      this.setState({ domain: domain ? domain : url });
    }
  }

  render() {
    return (
      <div className="paper">
        <div className="forgot-password-title">Forgot Password</div>
        <div className="greeting-log">
          Enter your domain and email to reset your password
        </div>
        <div>Domain</div>
        <input
          type="text"
          placeholder="Domain"
          name="domain"
          value={this.state.domain}
          onChange={(event) => this.onChange(event)}
        />
        <div>E-mail</div>
        <input
          type="text"
          placeholder="Email"
          name="email"
          value={this.state.email}
          onChange={(event) => this.onChange(event)}
        />
        <button
          className="button submit-btn"
          onClick={() => this.handleSubmit()}
        >
          Reset password
        </button>
      </div>
    );
  }

  async handleSubmit() {
    this.props.setLoading();
    try {
      if (this.hasValidCredentials()) {
        const response = await forgotPassword({
          domain: this.state.domain,
          email: this.state.email,
        });
        this.setState({ domain: "", email: "" });
        this.props.onSuccess({ message: response.data.data });
      }
    } catch (err: any) {
      this.props.onError({
        message:
          err?.response?.data.data[0].message ?? "Something went wrong!1",
      });
    }
    this.props.unsetLoading();
  }
  private hasValidCredentials(): boolean {
    if (this.state.domain.length > 0 && this.state.email.length > 0) {
      return true;
    }
    return false;
  }

  private onChange(event: any) {
    const { value, name } = event.target;
    this.setState({
      [name]: value,
    } as Pick<ForgotPasswordState, keyof ForgotPasswordState>);
  }
}
export default connect(undefined, {
  setLoading,
  unsetLoading,
  onSuccess,
  onError,
})(ForgotPassword);
