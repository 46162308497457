import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getItem, removeItem } from "../utilities/cookie";
import { signOut as logout } from "../utilities";

export enum PageRoute {
  LOGIN = "login",
  SERVICES = "services",
}

export const signOut = createAsyncThunk("signOut", async () => {
   await logout();
   return;
});
const initialState = { loginState: PageRoute.LOGIN, signOutTimeoutId: "" };
const loginSlice = createSlice({
  name: "login",
  initialState: {
    loginState: getItem().tokens.portal ? PageRoute.SERVICES : PageRoute.LOGIN,
    signOutTimeoutId: "",
  },
  reducers: {
    setServices: (state) => ({
      ...state,
      loginState: PageRoute.SERVICES,
    }),
    setSignOutTimeoutId: (state, { payload }) => ({
      ...state,
      signOutTimeoutId: payload,
    }),
    unsetServices: (state) => {
      clearInterval(state.signOutTimeoutId);
      removeItem();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signOut.fulfilled, (state) => {
        clearInterval(state.signOutTimeoutId);
        return initialState;
      })
      .addCase(signOut.rejected, (state) => {
        clearInterval(state.signOutTimeoutId);
        return initialState;
      });
  },
});

// destructure actions and reducer from the slice (or you can access as loginSlice.actions)
const { actions, reducer } = loginSlice;

// export individual action creator functions
export const { setServices, unsetServices, setSignOutTimeoutId } = actions;

// often the reducer is a default export, but that doesn't matter
export default reducer;
