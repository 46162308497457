import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import { graphBar } from "../../assets/images";
import Icon from "../../utilities/Icons";
import { RoleTypeName, getItem } from "../../utilities";

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "",
    },
  },
};

const Chart = ({
  graph,
  handleEditGraphClick,
  style,
}: {
  graph: any;
  handleEditGraphClick: Function;
  style?: React.CSSProperties
}) => {
  const id = getItem().user.role_id;
  return (
    <div className="relative-position">
      {handleEditGraphClick && graph && [RoleTypeName.admin, RoleTypeName.superadmin].includes(id) && (
        <Icon
          icon="edit-pencil"
          className="edit-graph"
          onClick={() => handleEditGraphClick()}
        />
      )}
      {graph ? (
        <HighchartsReact
          containerProps={{ style: { margin: "10px 0 20px", ...style } }}
          highcharts={Highcharts}
          options={graph}
        />
      ) : (
        <>
          <div className="config-button" onClick={() => handleEditGraphClick()}>
            {" "}
            Activate statistics module
          </div>
          <div className="no-graph second-graph">
            <div className="title-graph">Ongoing call</div>
            <div className="description-graph">Data information subtitle.</div>
            <img src={graphBar} alt="" />
          </div>
        </>
      )}
    </div>
  );
};
export default Chart;
