import React, { ReactNode, Component } from "react";
import { TwoFactor, SendEmail } from "../../services";
import "../../assets/styles/twoFactorAuth.css";
import "../../assets/styles/log.css";
import QRCode from "react-qr-code";
import OtpInput from "react-otp-input";
import { onError, onSuccess, setLoading,unsetLoading } from "../../reducers";
import { connect } from "react-redux";
import { getItem } from "../../utilities";
import Timer from 'otp-timer'

interface TwoFactorAuthProps {
  onSuccessfulOf2FASubmit: Function;
  setLoading: Function;
  unsetLoading:Function;
  onError: Function;
  onSuccess: Function;
}

interface TwoFactorAuthState {
  hasError: boolean;
  sessionToken: string | undefined | null;
  token: string;
  rememberMe: boolean;
  otpauth_url: string | undefined | null;
  showCode: string | undefined;
  resendOTP: boolean;
  mfa_notify_email:boolean;
}

class TwoFactorAuth extends Component<TwoFactorAuthProps, TwoFactorAuthState> {
  constructor(props: TwoFactorAuthProps) {
    super(props);
    const { remember_me, two_factor_session_token, otpauth_url, mfa_notify_email} = getItem();
    this.state = {
      rememberMe: !!remember_me,
      sessionToken: two_factor_session_token,
      otpauth_url: otpauth_url,
      hasError: false,
      token: "",
      showCode: "",
      resendOTP: true,
      mfa_notify_email: !!mfa_notify_email
    };
  }
  render(): ReactNode {
    return (
      <div className="Two-Factor-Paper ">
        <div className="Two-Factor-title">Two-factor authentication</div>
        <div className="greeting-log">
          Enter the code you received in your authenticator app.
        </div>
        <div id="login-form">
          {this.state.otpauth_url && (
            <div className="qrCode">
              <div className="Two-Factor-Qr-title">
                Scan QR code in authenticator app (for example Google
                Authenticator).
              </div>
              <QRCode value={this.state.otpauth_url} size={150} />
              {!this.state.showCode ? (
                <div
                  className="link mLink"
                  onClick={() => this.showSecretCode()}
                >
                  Can't scan QR? Get code.
                </div>
              ) : (
                <div className={"mLink"}>{this.state.showCode}</div>
              )}
              <br/>
              {this.getMFAEmailSegment()}
            </div>
          )}
          <OtpInput
            containerStyle={{
              justifyContent: "center",
            }}
            value={this.state.token}
            hasErrored={this.state.hasError}
            errorStyle={{ border: "1px solid red" }}
            inputStyle={{ margin: 5 }}
            isInputNum={true}
            shouldAutoFocus={true}
            onChange={(event) => this.onChange(event)}
            numInputs={6}
            separator={<span></span>}
          />
        </div>
      </div>
    );
  }

  getMFAEmailSegment(): React.ReactNode {
    return this.state.mfa_notify_email && <React.Fragment>
      <span>OR</span>
      <br/>
      <div className="greeting-log">
        Click here to get authentication code over your email
      </div>

      {this.state.resendOTP && <div
        className="link mLink"
        onClick={() =>  {
          SendEmail({
            two_factor_session_token: this.state.sessionToken,
          }),
          this.setState({resendOTP: false})
        }}
      >
       receive code over email
      </div>}

      {!this.state.resendOTP && <Timer
        text="Time Left"
        ButtonText="Resend OTP"
        minutes={2}
        resend={()=>{SendEmail({two_factor_session_token: this.state.sessionToken})}}
        background={"#0000ff"}
       />
      }
    </React.Fragment>
  }

  async handleSubmit() {
    try {
      this.props.setLoading();
      const response = await TwoFactor({
        two_factor_auth_token: this.state.token,
        remember_me: this.state.rememberMe,
        two_factor_session_token: this.state.sessionToken,
      });
      this.props.onSuccessfulOf2FASubmit(response.data);
      this.props.onSuccess({ message: "Successfully Login" });
    } catch (error: any) {
      this.setError();
      this.props.onError({
        message: error?.response?.data.data[0].message ?? "Invalid code",
      });
      this.setState({ token: "" });
    }
    this.props.unsetLoading();
  }
  private onChange(otp: string) {
    this.setState(
      {
        token: otp,
      },
      () => {
        if (otp.length === 6) {
          this.handleSubmit();
        }
      }
    );
  }
  private showSecretCode() {
    const code = this.state.otpauth_url?.split("=")[1];
    this.setState({ showCode: code });
  }
  private setError() {
    this.setState(
      {
        hasError: true,
      },
      () =>
        setTimeout(
          () =>
            this.setState({
              hasError: false,
            }),
          1000
        )
    );
  }
}

export default connect(undefined, { setLoading,unsetLoading, onError, onSuccess })(
  TwoFactorAuth
);
