import "./assets/styles/common.css";
import React from "react";
import RouterProvider from "./Routes";
import rootReducer from "./store";
import { Provider } from "react-redux";
import Loading from "./components/Loading";
import Snackbar from "./components/SnackBar";
import Popup from "./components/PopUp";
import config from "./config";

const root = (
  <div className="App">
    <Provider store={rootReducer}>
      <Loading />
      <Snackbar />
      <Popup />
      <RouterProvider />
    </Provider>
  </div>
)

function App() {
  if(config.environment === "production") {
    return <React.StrictMode children={root} />;
  }

  return root;
}

export default App;
