import Chart from "./Chart";
import { PureComponent } from "react";
import { getUserStatistics } from "../../services";
import "../../assets/styles/graph.css";
import { flattenNodes, getItem, openEditGraphPage, RoleTypeName } from "../../utilities";
import { connect } from "react-redux";
import { setLoading, setPopup, unsetLoading, unsetPopup } from "../../reducers";
import { State } from "../../store";
import { activateStatistics, NodeListStateType } from "../../interfaces";
import { graphBar } from "../../assets/images";

interface GraphProps {
  nodeList: NodeListStateType;
  setPopup: Function;
  setLoading: Function;
  unsetLoading: Function;
}

interface GraphState {
  graphs: Array<any>;
  statisticsUrl: string;
  status: activateStatistics | "";
}

function NoGraph({handleEditGraphClick}) {
  return (
    <>
      <div className="no-graph">
        <div className="title-graph">Ongoing call</div>
        <div className="description-graph">Data information subtitle.</div>
        <img src={graphBar} alt="" />
      </div>
      <div className="no-graph">
        <div className="title-graph">Ongoing call</div>
        <div className="description-graph">Data information subtitle.</div>
        <img src={graphBar} alt="" />
      </div>
      <div className="disable-graph-message">
        <div className="title">Statistics</div>
        <div className="description">
          Uncover the power of data and statistical insights
        </div>
        <div className="button" onClick={handleEditGraphClick}>
          Activate statistics module
        </div>
      </div>
    </>
  );
}
class graph extends PureComponent<GraphProps, GraphState> {
  constructor(props: GraphProps) {
    super(props);
    this.state = {
      graphs: [],
      statisticsUrl: "",
      status: "",
    };
  }
  async componentDidUpdate(): Promise<void> {
    if (this.state.graphs.length === 0) {
      try {
        this.props.setLoading();
        const status = this.getCoreUrl(this.props.nodeList);
        this.setState({ status: status });
        if (
          status === activateStatistics.PERFECT ||
          (status === activateStatistics.AGENT && this.state.statisticsUrl)
        ) {
          const url = this.state.statisticsUrl.replace(/\/$/, "");
          const graphResponse = await getUserStatistics(url);
          const graphs = graphResponse.data.data?.map((statistics) => {
            const datasets = this.datasets(statistics);
            return this.formattedStatistic(
              statistics.title,
              datasets,
              statistics?.content?.s[0]?.c?.l
            );
          });
          if (graphs.length !== 0) {
            if (graphs.length === 1) {
              graphs.push(null);
            }
            this.setState({ graphs: graphs });
          }
        }
        this.props.unsetLoading();
      } catch (err) {
        this.props.unsetLoading();
      }
       this.props.unsetLoading();
    }
  }

  render() {
    return (
      <>
        <div className={`${this.state.graphs.length ? "" : "disabled-graph"}`}>
          {this.state.graphs.length ? (
            this.state.graphs.map((graph, index) => (
              <Chart
                key={index}
                graph={graph}
                handleEditGraphClick={this.handleEditGraphClick}
              />
            ))
          ) : (
            <NoGraph handleEditGraphClick={this.handleEditGraphClick}/>
          )}
        </div>
      </>
    );
  }
  private getTitleOfMetric(title: string, settings: any) {
    const metric = settings.metrics.filter(({ alias }) => alias === title);
    return metric?.[0]?.title;
  }
  private datasets(data: any): Array<any> {
    return data.content?.s[0]?.c?.d?.map((d: any) => ({
      data: d.d,
      name: this.getTitleOfMetric(d.m, data.settings),
    })) as Array<any>;
  }
  handleActivateSelect() {
    const id = getItem().user.role_id;
    if (id === RoleTypeName.agent) {
    }
  }
  getCoreUrl(response: any) {
    const flattenedNodes = flattenNodes([response], []);
    const node = flattenedNodes.find(
      (node) => node.id.toString() == response?.widgets_host
      );
    const widgets_host = node?.hostname;

    const id = getItem().user.role_id;
    if (
      [
        RoleTypeName.manager,
        RoleTypeName.agent,
        RoleTypeName.anonymous,
      ].includes(id)
    ) {
      this.setState({ statisticsUrl: widgets_host });
      return activateStatistics.AGENT;
    }
    if (response.skyview_license && response.insights_license) {
      if (response?.widgets_host) {
        this.setState({ statisticsUrl: widgets_host });
        return activateStatistics.PERFECT;
      } else if (
        id !== RoleTypeName.superadmin &&
        response?.nodes?.length === 1
      ) {
        this.setState({ statisticsUrl: response?.nodes[0].hostname });
        return activateStatistics.PERFECT;
      } else {
        return activateStatistics.NO_HOST;
      }
    }
    return activateStatistics.NO_LICENSE;
  }
  handleEditGraphClick = () => {
    if (
      this.state.statisticsUrl &&
      this.state.status === activateStatistics.PERFECT
    ) {
      const url = this.state.statisticsUrl.replace(/\/$/, "");
      openEditGraphPage(url);
    } else {
      this.props.setPopup({
        type: "statistic_message",
        message: this.state.status,
      });
    }
  };
  formattedStatistic = (title, datasets, categories) => ({
    chart: {
      type: "spline",
      height: (9 / 16) * 100 + "%",
      borderRadius: 10,
    },
    credits: {
      enabled: false
    },
    title: {
      text: title,
    },
    subtitle: {
      text: "Period - Last 30 days",
    },

    series: datasets,
    xAxis: {
      categories: categories,
    },
    yAxis: {
      title: {
        text: "Count",
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
      },
    },
  });
}

const mapStateToProps = (state: State) => ({
  nodeList: state.nodes,
});
const mapDispatchToProps = () => ({
  setPopup,
  setLoading,
  unsetLoading,
  unsetPopup,
});
export default connect(mapStateToProps, mapDispatchToProps())(graph);
