import React, { Component } from "react";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
export interface ITooltip {
  id: string;
  message?: string;
  messageElement?: React.ReactNode;
  className?: string;
  variant?: "dark" | "light" | "success" | "warning" | "error" | "info";
  place?: "top" | "right" | "bottom" | "left";
}

export default class Tooltips extends Component<ITooltip> {
  public render() {
    return (
      <Tooltip
        key={this.props.id}
        id={this.props.id}
        place={this.props.place ?? "top"}
        variant={this.props.variant ?? "info"}
        anchorSelect={`#${this.props.id}`}
        data-tooltip-html={true}
        className={this.props.className || ""}
      >
        {this.props.messageElement || this.props.message}
      </Tooltip>
    );
  }
}
