import { Component } from "react";
import { connect } from "react-redux";
import "../assets/styles/notification.css";
import { onError, onSuccess } from "../reducers";
import { postNotificationService } from "../services";
import { getPortalSocket } from "../services/socket";
import Icon from "../utilities/Icons";
import { getItem } from "../utilities";
import { State } from "../store";
import { NotificationPropsTypes } from "../interfaces";

interface NotificationsState {
  isNotificationsExpanded: boolean;
  socket: any;
  notifications: NotificationPropsTypes[];
  socket_connected: boolean;
  numberOfUnreadNotifications: number;
}

interface NotificationPropsType {
  onError: Function;
  onSuccess: Function;
  notifications: any[];
}
class NotificationsComponent extends Component<
  NotificationPropsType,
  NotificationsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      socket: null,
      socket_connected: false,
      notifications: [],
      isNotificationsExpanded: false,
      numberOfUnreadNotifications: 0,
    };
  }
  componentDidUpdate(prevProps: NotificationPropsType): void {
    if (prevProps.notifications !== this.props.notifications) {
      this.getNotifications(this.props.notifications);
      this.connectPortalSocket();
    }
  }

  render(): React.ReactNode {
    const numberOfUnreadNotifications = this.state.numberOfUnreadNotifications;
    const isNotificationsExpanded = this.state.isNotificationsExpanded;
    return (
      <div
        className={"dropdown"}
        id="notifications"
        onBlur={this.handleClickOutside}
        tabIndex={1}
      >
        <div id="notification-bell" className={"bell"}>
          {!!numberOfUnreadNotifications && (
            <div
              className="batch"
              style={{
                display: `${
                  numberOfUnreadNotifications > 0 ? "block" : "none"
                }`,
              }}
            >
              {numberOfUnreadNotifications > 9
                ? "9+"
                : numberOfUnreadNotifications}
            </div>
          )}
          <span
            className={`bell-icon ${
              !!numberOfUnreadNotifications && "rotate-icon"
            }`}
          >
            <Icon
              icon={"notifications"}
              onClick={() =>
                this.setState({
                  isNotificationsExpanded: !this.state.isNotificationsExpanded,
                })
              }
            />
          </span>
        </div>
        {isNotificationsExpanded ? (
          <div id="notification-menu" className={"notification-menu"}>
            <div className="notification-header">
              Notifications
              <span
                className="markAllAsRead"
                onClick={() => this.markAllAsRead()}
              >
                Mark all as read
              </span>
            </div>
            <div className="notification-content" id="content">
              {this.state.notifications.length > 0
                ? this.createNotificationsItems()
                : this.noNotification()}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>
    );
  }
  getRedirectionUrl(url: string, notificationActionId: number) {
    if (notificationActionId === 2) {
      const { tokens, user } = getItem();
      const service = url.includes("skyview") ? "skyview" : "insights";
      url += `?token=${tokens[service]}&username=${user.username}`;
    }
    return url;
  }
  createNotificationsItems() {
    return this.state.notifications.map((notification) => {
      let redirectionUrl;
      if (notification.metadata?.url) {
        redirectionUrl = this.getRedirectionUrl(
          notification.metadata.url,
          notification.notificationActionId
        );
      }
      const isRead = notification.read === 1;
      return (
        <div
          id={`n-${notification.id}`}
          key={`n-${notification.id}`}
          title={`${isRead ? "" : "Click to mark read"}`}
          className={`notification-card ${notification.read === 1 && "unread"}`}
        >
          <div className="notification-card-bar">
            <Icon icon={`${isRead ? "envelope-open-o" : "envelope-o"}`} />
            <span className="notification-title">{notification.title}</span>
            <span className="time-text">{notification.createdAt}</span>
          </div>
          <p className="notification-message">{notification.message}</p>
          {redirectionUrl && (
            <div
              className="notification-redirect"
              onClick={() => window.open(redirectionUrl, "_blank")}
            >
              click here to visit
            </div>
          )}
        </div>
      );
    });
  }
  noNotification = () => {
    return (
      <div className="noNotification">
        <div className="subtext">You don't have any notification</div>
      </div>
    );
  };

  handleClickOutside = (event: any) => {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({ isNotificationsExpanded: false });
    }
  };
  async markAllAsRead() {
    try {
      const readNotifications = this.state.notifications.map(({ id }) => id);
      await postNotificationService({
        notificationIds: readNotifications,
      });
      this.props.onSuccess({ message: "Marked readed successfully!!" });
      // this.getNotifications();
    } catch (e) {
      this.props.onError({ message: "Try again!!" });
    }
  }
  async getNotifications(notifications) {
    this.setState(
      {
        notifications: notifications,
      },
      () => this.setNotificationBatch()
    );
  }

  setNotificationBatch() {
    this.setState({
      numberOfUnreadNotifications: this.state.notifications.filter(
        (n: { read: number }) => !n.read
      ).length,
    });
  }
  private async connectPortalSocket() {
    if (!this.state.socket_connected) {
      const socket = getPortalSocket();
      socket.on("connect", (resp: any) => {
        this.setState({ socket_connected: socket.connected, socket: socket });
        socket.emit("notifications:request", {});
        socket.on("notifications:response", (response: any) =>
          this.setState(
            {
              notifications: response,
            },
            () => this.setNotificationBatch()
          )
        );
      });
    }
  }
}
const mapStateToProps = (state: State) => ({
  notifications: state.notifications,
});
export default connect(mapStateToProps, { onError, onSuccess })(
  NotificationsComponent
);
