export enum License {
  PORTAL = "portal",
  INSIGHTS = "insights",
  SKYVIEW = "skyview",
  SURVEY = "survey",
  AGENT_USER = "agent/user",
  CHATBOT = "Chatbot",
  VOICEBOT = "Voicebot",
  SPEECH = "Speech Analytics",
  WFM = "WFM",
  TEXT_MESSAGE = "Text Messaging",
  KNOWLEDGE_BASE = "Knowledge Base",
}
