enum PopupPropsType {
  SESSION_TIMEOUT = "session_timeout",
  STATISTIC_MESSAGE = "statistic_message",
  DEFAULT = "default",
  NONE = "",
}

export interface popupStateType {
  message?: string;
  type: PopupPropsType;
}
export const initialState: popupStateType = {
  type: PopupPropsType.NONE,
  message: "",
};
